import { View } from "@react-pdf/renderer";
import currency from "currency.js";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import i18n from "@/app/lib/i18n";
import { OrderPosition, TradePosition } from "@/app/types/generated/graphql";

import { styles } from "../../pdf-styles";
import { DATE_TIME_FORMAT } from "../../react-pdf-helpers";
import { PairTableRow } from "../common/pair-table-row";
import { TransactionSummaryOrder } from "./transaction-summary.types";

export const getDefaultEmptyString = (
  position?: OrderPosition | TradePosition,
  { ignorePosition = false, bidDefaultEmptyString }: { ignorePosition?: boolean; bidDefaultEmptyString?: string } = {},
) => {
  if (ignorePosition) {
    return i18n.t("Any");
  }

  if (position === OrderPosition.Bid) {
    return i18n.t(bidDefaultEmptyString || "Any");
  }

  return i18n.t("N/A");
};

export const OrderTable = ({ order, tradeType }: { order: TransactionSummaryOrder; tradeType: "Spot" | "Forward" }) => {
  const { t } = useTranslation();
  const isForwardTrade = tradeType === "Forward";

  return (
    <View style={styles.tableContainer}>
      <PairTableRow header leftValue={t("Attribute")} rightValue={t("Value")} />
      <PairTableRow
        leftValue={t("Order Date")}
        rightValue={order.orderDate && format(new Date(order.orderDate), DATE_TIME_FORMAT)}
      />
      <PairTableRow leftValue={t("Marketplace")} rightValue={order.marketplace} />
      <PairTableRow leftValue={t("Participant")} rightValue={order.participantName} />
      {isForwardTrade && order.counterPartyName ? (
        <PairTableRow leftValue={t("Counterparty")} rightValue={order.counterPartyName} />
      ) : (
        <></>
      )}
      <PairTableRow leftValue={t("Order Type")} rightValue={t(order.position)} />
      {!isForwardTrade && order.contractIDs ? (
        <PairTableRow leftValue={t("Contract IDs")} rightValue={order.contractIDs.join(", ") || t("N/A")} />
      ) : (
        <></>
      )}
      {isForwardTrade && order.volume ? (
        <PairTableRow leftValue={t("Quantity")} rightValue={order.volume.toLocaleString()} />
      ) : (
        <></>
      )}
      <PairTableRow leftValue={t("Asset")} rightValue={order.assetType} />
      <PairTableRow
        leftValue={t("Total Order Value")}
        rightValue={`${currency(order.totalOrderValue, { fromCents: true }).format()} ${order.currencyCode}`}
      />
      <PairTableRow leftValue={t("Product")} rightValue={t("As described in the Contract Product Attributes below")} />
      <PairTableRow
        leftValue={t("Eligibility")}
        rightValue={
          order.attributes.eligibilities?.join(", ") ||
          getDefaultEmptyString(order.position, { ignorePosition: isForwardTrade })
        }
      />
      <PairTableRow
        leftValue={t("Fuel Source")}
        rightValue={
          order.attributes.fuelSources?.join(", ") ||
          getDefaultEmptyString(order.position, { ignorePosition: isForwardTrade })
        }
      />
      <PairTableRow
        leftValue={t("Location")}
        rightValue={
          order.attributes.locations?.join(", ") ||
          getDefaultEmptyString(order.position, { ignorePosition: isForwardTrade })
        }
      />
      <PairTableRow
        leftValue={t("Vintage")}
        rightValue={order.attributes.vintages?.join(", ") || getDefaultEmptyString(order.position)}
      />
      <PairTableRow
        leftValue={t("Certification Schemes")}
        rightValue={
          order.attributes.certifications?.join(", ") ||
          getDefaultEmptyString(order.position, { ignorePosition: isForwardTrade })
        }
      />
    </View>
  );
};
