import { Option } from "@powerledger/ui-component-lib";

import { AssetByAssetCodeQuery } from "../types/generated/graphql";

type Value = { displayName?: string | null; code: string };

type GroupByKeyArg = {
  values: Array<Value & { key: string }>;
};

export type FormattedOption = Array<
  Option & {
    options?: Option[];
  }
>;

const sortOptions = (values: FormattedOption, sort: 1 | -1) =>
  values.sort((valA, valB) => (valA?.label?.toLocaleLowerCase() > valB?.label?.toLocaleLowerCase() ? sort : -sort));

const groupByKey = (values: GroupByKeyArg["values"], sort: 1 | -1): FormattedOption => {
  const keyObj: Record<string, Option[]> = {};
  for (const val of values) {
    keyObj[val.key] = [...(keyObj[val.key] || []), { label: val.displayName || "", value: val.code }];
  }
  const groupedOptions = [];
  for (const val in keyObj) {
    groupedOptions.push({
      label: val,
      value: null,
      options: sortOptions(keyObj[val], sort),
    });
  }
  return groupedOptions;
};

const extractAttributeOptions = (
  attributeName: string,
  attributes?: { name: string; values?: (Value & { key?: string | null })[] | null }[],
  /** 1 for ascending, -1 for descending */
  sort: 1 | -1 = 1,
): FormattedOption => {
  const attribute = attributes?.find((attr) => attr.name === attributeName);
  let options: Array<
    Option & {
      options?: Option[];
    }
  >;
  if (attribute?.values?.[0]?.key) {
    options = groupByKey((attribute?.values as GroupByKeyArg["values"]) || [], sort);
  } else {
    options =
      attribute?.values?.map((val) => ({
        label: val.displayName || "",
        value: val.code,
      })) || [];
  }
  return sortOptions(options, sort);
};

export type RECOrderAttributeOptionKeys =
  | "vintageOptions"
  | "eligibilityOptions"
  | "vintageHalfOptions"
  | "locationOptions"
  | "fuelSourceOptions"
  | "projectOptions"
  | "certificationOptions";

export type RECOrderAttributeOptions = Record<RECOrderAttributeOptionKeys, FormattedOption>;

export const formatRecOptions = (data: AssetByAssetCodeQuery["assetByAssetCode"]): RECOrderAttributeOptions => {
  const attributes = data?.attributes;
  if (!attributes) {
    throw new Error("No attributes found in REC order attributes options");
  }

  return {
    vintageOptions: extractAttributeOptions("vintage", attributes, -1),
    eligibilityOptions: extractAttributeOptions("eligibility", attributes),
    locationOptions: extractAttributeOptions("location", attributes),
    fuelSourceOptions: extractAttributeOptions("fuelSource", attributes),
    projectOptions: extractAttributeOptions("project", attributes),
    certificationOptions: extractAttributeOptions("certification", attributes),
    vintageHalfOptions: extractAttributeOptions("vintageHalf", attributes, -1),
  };
};
