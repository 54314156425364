import { Box, Button, ControlledToggle, Flex, IconLoading, Text } from "@powerledger/ui-component-lib";
import currency from "currency.js";
import { Formik } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import * as Yup from "yup";

import { Form, FormFieldLabel } from "@/app/components/form";
import { LoadingOverlayIndicator } from "@/app/components/loading-overlay-indicator";
import { getTotalValue, isWithinLimit } from "@/app/lib/order-helpers";

import { LocalDatePicker } from "../../date-picker";
import { RecFormFilter } from "../../rec-form-filter";
import { ForwardSummaryAndAgreement, OrderLifeCycle, VolumeAndPriceInputFields } from "../common";
import { Confirmation } from "../confirmation";
import { ForwardTradeFormViewProps } from "./forward-trade-order-modal.types";

export const ForwardTradeForm: FC<ForwardTradeFormViewProps> = ({
  filters,
  stackedValues,
  removeValue,
  formikInitialValue,
  handleSubmit,
  currencySymbol,
  overLimitError,
  setOverLimitError,
  loading,
  recOptionsLoading,
  closeModal,
  currentStep,
  isFilterValid,
  tradePosition,
  isPositionBid,
  toggleTradePosition,
  isSellOrderDisabled,
  handleFieldChange,
}) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  if (currentStep === OrderLifeCycle.Success) {
    return (
      <Confirmation
        text={t("Your forward trade order has been received.")}
        action={{
          content: t("View your order here"),
          onClick: () => {
            navigate("/orders/forward-trade");
            closeModal();
          },
        }}
      />
    );
  }

  if (loading) {
    return <LoadingOverlayIndicator />;
  }

  return (
    <Box>
      <Formik
        initialValues={formikInitialValue}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          unitPrice: Yup.string()
            .required(t("Enter valid amount"))
            .test("positive-number", t("Please enter a positive number"), (value = "0") => currency(value).value > 0),
          volume: Yup.number()
            .required(t("Enter valid amount"))
            .positive(t("Enter valid amount"))
            .test("whole-number", t("Enter valid amount"), (value = 0) => Number.isInteger(value)),
          settlementDate: Yup.string().required("Settlement date is required"),
          vintage: Yup.string().required("Vintage is required"),
          tncAgreed: Yup.boolean().oneOf([true], t("Please agree to the terms and conditions")),
          disclosureAgreement: Yup.boolean().oneOf([true], t("Please agree to the terms and conditions")),
        })}
        validateOnMount
      >
        {({ handleSubmit, isSubmitting, values, isValid, touched, setFieldValue, setTouched }) => {
          return (
            <Box>
              <Flex sx={{ justifyContent: "space-between" }}>
                <Text sx={{ fontSize: 4, fontWeight: "bold" }}>{t("Forward Order")}</Text>

                <Flex sx={{ alignSelf: "flex-end", flexDirection: "column", width: "50%", pl: 2 }}>
                  <Text sx={{ fontSize: 2, mb: 2, fontWeight: "bold" }}>{t("I'm looking to")}</Text>
                  <ControlledToggle
                    selected={!isPositionBid}
                    onClick={toggleTradePosition}
                    optionOne="Buy"
                    optionTwo="Sell"
                    style={{
                      button: {
                        height: "40px",
                        width: "238px",
                      },
                    }}
                    disabled={isSellOrderDisabled || isSubmitting}
                  />
                </Flex>
              </Flex>
              <Form onSubmit={handleSubmit}>
                <Flex mt={2}>
                  <Flex
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      width: "50%",
                      mr: 3,
                      maxHeight: "min-content",
                      overflow: "visible",
                      pr: 3,
                    }}
                  >
                    <Text sx={{ fontSize: 3, mb: 2 }}>{t("Trade Attributes")}</Text>
                    <VolumeAndPriceInputFields isPositionBid={isPositionBid} currencySymbol={currencySymbol} />
                    <Form.Item sx={{ flex: 1 }}>
                      <FormFieldLabel small name="settlementDate" label={t("Trade Settlement Date *")}>
                        <LocalDatePicker
                          value={values.settlementDate}
                          minDate={new Date()}
                          onBlur={() => setTouched({ ...touched, settlementDate: true })}
                          onChange={(date) => {
                            handleFieldChange("settlementDate", date, setFieldValue);
                          }}
                          disabled={stackedValues?.some((value) => value.type === "selectedDate")}
                        />
                      </FormFieldLabel>
                    </Form.Item>

                    <Text sx={{ fontSize: 3, mb: 2 }}>{t("Product Attributes")}</Text>

                    {filters.map((filter) => {
                      const fieldName = filter.name.replace(/\s/g, "-").toLowerCase();
                      return (
                        <RecFormFilter
                          key={fieldName}
                          filter={filter}
                          removeValue={removeValue}
                          stackedValues={stackedValues}
                          disabled={recOptionsLoading}
                          onBlur={() => {
                            if (filter.keys === "vintages" && filter.isRequired) {
                              setTouched({ ...touched, [fieldName]: true });
                            }
                          }}
                          onChange={(value) => {
                            if (filter.keys === "vintages" && filter.isRequired) {
                              handleFieldChange("vintage", value, setFieldValue);
                            }
                          }}
                        />
                      );
                    })}
                  </Flex>
                  <ForwardSummaryAndAgreement
                    {...{
                      setFieldValue,
                      currencySymbol,
                      isPositionBid,
                      tradePosition,
                      unitPrice: values.unitPrice,
                      volume: values.volume,
                      fee: values.fee,
                      disclosureAgreement: values.disclosureAgreement,
                      disclosureText:
                        "I, as Authorized Representative, on behalf of the Participant,  consent to the disclosure of the Participant name and email address to any Participant to whom this Forward Order is displayed so that the counterparty Participant may conduct Counterparty Assessment (as defined in the TraceX Rules). You may withdraw your consent for future disclosures at any time, but if you do so, you will not be able to place or Accept the Forward Orders and your withdrawal will not affect any prior disclosures.",
                      tncAgreed: values.tncAgreed,
                      agreementDisclaimerText:
                        "By clicking on “Place Order” I confirm that I am placing a Forward Order said out in accordance with TraceX Rules. Upon another Participant accepting your Order, a binding Pending Forward Trade will be created.  If the Pending Forward Trade is approved by both counterparties, a Pending Forward Trade will result in a binding Forward Contract on the terms set out in the TraceX Rules.",
                    }}
                    requireAgreement
                  >
                    <Flex sx={{ flexDirection: "column" }}>
                      <Flex sx={{ pt: 2 }}>
                        {isValid && overLimitError && (
                          <Text sx={{ fontSize: 0, color: "error.500" }}>{t(overLimitError)}</Text>
                        )}
                      </Flex>
                      <Flex
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                          mt: 2,
                          fontFamily: "MintGroteskV08",
                        }}
                      >
                        <Flex sx={{ flex: 1, justifyContent: "flex-end" }}>
                          {isSubmitting ? (
                            <IconLoading size="small" />
                          ) : (
                            <Button
                              type="submit"
                              variant="primary"
                              sx={{ px: 4 }}
                              disabled={
                                isSubmitting ||
                                !isValid ||
                                !isFilterValid ||
                                !isWithinLimit(
                                  getTotalValue(values.unitPrice, values.volume, tradePosition, values.fee),
                                  setOverLimitError,
                                )
                              }
                            >
                              {t("Place Order")}
                            </Button>
                          )}
                        </Flex>
                      </Flex>
                    </Flex>
                  </ForwardSummaryAndAgreement>
                </Flex>
              </Form>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
};
