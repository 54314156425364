import { Image, Text, View } from "@react-pdf/renderer";
import { format, parseISO } from "date-fns";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { OrderPosition, TradePosition } from "@/app/types/generated/graphql";
import CRSLogo from "@/assets/logos/crs-logo.png?url";

import { styles } from "../../pdf-styles";
import { PairTableRow } from "../common/pair-table-row";
import { getDefaultEmptyString } from "./order-table";
import { TransactionSummaryTransactionContractProductAttributes } from "./transaction-summary.types";

const CertificationAttributeColumnContent = ({
  children,
  certification,
}: {
  certification?: string;
  children: React.ReactNode;
}) => {
  const mapCertificationLogo = useMemo(
    () =>
      certification?.includes("CRS") ? (
        <Image src={CRSLogo} style={{ alignSelf: "center", width: 23, height: 7 }} />
      ) : null,
    [certification],
  );
  return (
    <View style={{ display: "flex", flexDirection: "row" }}>
      {mapCertificationLogo}
      <Text>&nbsp;{children}</Text>
    </View>
  );
};

export const ProductAttributesTable = ({
  position,
  tradeType,
  attributes,
}: {
  position: OrderPosition | TradePosition;
  tradeType: "Spot" | "Forward";
  attributes: TransactionSummaryTransactionContractProductAttributes;
}) => {
  const { t } = useTranslation();
  const ignorePosition = tradeType === "Forward";

  return (
    <View break style={styles.tableContainer} wrap={false}>
      <PairTableRow header leftValue={t("Contract Product Attributes")} />
      {attributes.volume ? (
        <PairTableRow leftValue={t("Product Quantity")} rightValue={attributes.volume.toLocaleString()} />
      ) : (
        <></>
      )}
      <PairTableRow
        leftValue={t("Eligibility")}
        rightValue={attributes.eligibilities?.join(",") || getDefaultEmptyString(position, { ignorePosition })}
      />
      <PairTableRow
        leftValue={t("Fuel Source")}
        rightValue={attributes.fuelSources?.join(",") || getDefaultEmptyString(position, { ignorePosition })}
      />
      <PairTableRow leftValue={t("Applicable Tracking System")} rightValue={attributes.trackingSystem} />
      <PairTableRow
        leftValue={t("Project Name")}
        rightValue={attributes.projects?.join(",") || getDefaultEmptyString(position, { ignorePosition })}
      />
      <PairTableRow
        leftValue={t("Project Location")}
        rightValue={attributes.locations?.join(",") || getDefaultEmptyString(position, { ignorePosition })}
      />
      <PairTableRow
        leftValue={t(`Vintage${attributes.vintages?.length ? " (mm-yyyy)" : ""}`)}
        rightValue={attributes.vintages?.join(",") || getDefaultEmptyString(position, { ignorePosition })}
      />
      <PairTableRow
        leftValue={t("Certification Schemes")}
        rightValue={
          attributes.certificationScheme?.certifications?.join(",") ||
          getDefaultEmptyString(position, { ignorePosition })
        }
      />
      <PairTableRow
        leftValue={
          <CertificationAttributeColumnContent certification="CRS">
            {t("Facility Effective Date")}
          </CertificationAttributeColumnContent>
        }
        rightValue={
          attributes.certificationScheme?.crsEffectiveDate
            ? format(parseISO(attributes.certificationScheme.crsEffectiveDate), "P")
            : "N/A"
        }
      />
      <PairTableRow
        leftValue={
          <CertificationAttributeColumnContent certification="CRS">
            {t("Facility Expiry Date")}
          </CertificationAttributeColumnContent>
        }
        rightValue={
          attributes.certificationScheme?.crsExpiryDate
            ? format(parseISO(attributes.certificationScheme.crsExpiryDate), "P")
            : "N/A"
        }
      />
      <PairTableRow
        leftValue={t("Commenced Operation Date")}
        rightValue={
          attributes.certificationScheme?.commencementDate
            ? format(parseISO(attributes.certificationScheme.commencementDate), "P")
            : getDefaultEmptyString(position, { ignorePosition })
        }
      />
    </View>
  );
};
