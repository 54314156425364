import { ApolloError } from "@apollo/client";
import { useState } from "react";

import { useAppContext } from "@/app/context/app-context";
import { formatRecOptions, RECOrderAttributeOptions } from "@/app/lib/format-rec-options";
import { AccountStatus, AssetType, useAssetByAssetCodeQuery } from "@/app/types/generated/graphql";

export const useRecOptions = () => {
  const [formattedRecOptions, setFormattedRecOptions] = useState<RECOrderAttributeOptions>();
  const { userAccountInfo, userSelections } = useAppContext();
  const [accumulatedErrors, setAccumulatedErrors] = useState<ApolloError | Error | undefined>();

  const { loading: assetsLoading } = useAssetByAssetCodeQuery({
    fetchPolicy: "cache-and-network",
    skip: !userAccountInfo?.tenantId || userAccountInfo?.status !== AccountStatus.Active,
    variables: {
      assetCode: AssetType.Rec,
      registryCode: userSelections?.registry?.registryCode,
    },
    onCompleted: (data) => {
      try {
        const formattedOptions = formatRecOptions(data?.assetByAssetCode);
        setFormattedRecOptions(formattedOptions);
      } catch (error) {
        if (error instanceof Error) {
          setAccumulatedErrors(error);
        }
      }
    },
    onError: (error) => {
      setAccumulatedErrors(error);
    },
  });

  if (accumulatedErrors) {
    throw accumulatedErrors;
  }

  return {
    recOptions: formattedRecOptions,
    loading: assetsLoading || !userAccountInfo?.loaded,
    error: accumulatedErrors,
  };
};
