import { alpha, Box, Flex, Tabs } from "@powerledger/ui-component-lib";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { AccountVerificationStatus } from "./account-verification-status";
import { CompanyDetailsTab } from "./company-details-tab/company-details-tab";
import { companyDetailsStatusIconMap } from "./company-details-tab/useCompanyDetailsStatusIcon";
import { PaymentServicesTab } from "./payment-services-tab";
import { PersonalDetailsTab } from "./personal-details-tab";
import { RegistryTab } from "./registry-tab";
import { SettingsViewProps, SettingTab } from "./settings.types";
import { UserInfo } from "./user-info";

export const SettingsView: React.FC<SettingsViewProps> = ({
  switchTab,
  currentTab,
  isAdmin,
  companyDetailsIconName,
  paymentServicesIcon,
  registryIcon,
}) => {
  const { t } = useTranslation();
  const tabs = useMemo(() => {
    const baseTabs: SettingTab[] = [{ title: t("User Details"), key: "details", component: <PersonalDetailsTab /> }];
    if (!isAdmin) {
      return baseTabs;
    }
    baseTabs.push(
      ...[
        {
          title: t("Primary Details"),
          IconComponent: companyDetailsIconName ? companyDetailsStatusIconMap[companyDetailsIconName] : null,
          key: "company",
          component: <CompanyDetailsTab />,
        },
        {
          title: t("Payment Services"),
          IconComponent: paymentServicesIcon,
          key: "paymentServices",
          component: <PaymentServicesTab />,
        },
        {
          title: t("Registry"),
          IconComponent: registryIcon,
          key: "registry",
          component: <RegistryTab />,
        },
      ],
    );

    return baseTabs;
  }, [t, companyDetailsIconName, isAdmin, paymentServicesIcon, registryIcon]);
  return (
    <Box as="main" sx={{ m: 4 }}>
      <Flex sx={{ alignItems: "center", justifyContent: "space-between", mb: 4 }}>
        <UserInfo />
        <Flex sx={{ alignItems: "center" }}>
          <AccountVerificationStatus isAdmin={isAdmin} />
        </Flex>
      </Flex>
      <Tabs
        activeKey={currentTab as unknown as string}
        onChange={switchTab}
        sx={{ maxHeight: "inherit", position: "relative" }}
      >
        {tabs.map(({ title, key, IconComponent, component }) => (
          <Tabs.TabPane
            tab={
              <>
                {IconComponent && (
                  <IconComponent
                    size={5}
                    color={"textDarker"}
                    sx={{
                      position: "absolute",
                      top: 0,
                      mt: -3,
                      pointerEvents: "none",
                    }}
                  />
                )}
                <Flex sx={{ alignItems: "center" }}>{title}</Flex>
              </>
            }
            key={key}
          >
            <Box sx={{ p: 5, bg: alpha("text", 0.05), minHeight: 500 }}>{component}</Box>
          </Tabs.TabPane>
        ))}
      </Tabs>
    </Box>
  );
};
