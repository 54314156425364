import { Box } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { PageHeader } from "@/app/components/page-header/page-header";
import { AccountType } from "@/app/types/generated/graphql";

import { RecHoldingsTable } from "./components";
import { RecHoldingsViewProps } from "./rec-holdings.types";

export const RecHoldingsView: FC<RecHoldingsViewProps> = ({
  pageTitle,
  recOptions,
  handleFiltersChange,
  refetchHoldings,
  selected,
  onSell,
  setSelected,
  loading = false,
  recHoldingsTableData,
  pageInfo,
  fetchData,
  hasPermissions,
  onRetire,
  onExport,
}) => {
  const { t } = useTranslation();

  return (
    <Box as="main" sx={{ m: 4 }}>
      <PageHeader
        title={t(pageTitle)}
        filterProps={{
          applyFilters: handleFiltersChange,
          shouldSelectVintage: false,
          hideStatusFilter: true,
        }}
        actions={{
          sellAction: {
            hide: !hasPermissions({ allowedAccountTypes: [AccountType.Registry] }),
            disable: !selected.length,
            onClick: onSell,
          },
          retireAction: {
            hide: !hasPermissions({ allowedAccountTypes: [AccountType.NonRegistry] }),
            disable: !selected.length,
            onClick: onRetire,
          },
          exportAction: {
            hide: !hasPermissions({
              allowedAccountTypes: [AccountType.Registry],
            }),
            disable: !selected.length,
            onClick: onExport,
          },
        }}
      />
      <RecHoldingsTable
        data={recHoldingsTableData}
        pageInfo={pageInfo}
        fetchData={fetchData}
        loading={loading}
        recOptions={recOptions}
        selected={selected}
        setSelected={setSelected}
        refetchHoldings={refetchHoldings}
      />
    </Box>
  );
};
