import { IconInfo } from "@powerledger/ui-component-lib";
import { TFunction } from "i18next";
import { Column, Row } from "react-table";
import { Flex, Text } from "theme-ui";

import { EligibilityValueFormatter } from "@/app/components/eligibility-value-formatter";
import { LocationValueWrapper } from "@/app/components/location-value-wrapper/location-value-wrapper";
import { TableValueWrapper } from "@/app/components/table-value-wrapper";
import { CertificationAndEliglibilityType } from "@/app/lib/format-attributes";
import { RECOrderAttributeOptions } from "@/app/lib/format-rec-options";
import { getFuelSourceIcon } from "@/app/lib/get-fuel-source-icon";
import { OrderPosition } from "@/app/types/generated/graphql";

import { RecHoldingsTableData } from "../rec-holdings/rec-holdings.types";

export function getCommonRecTableData(t: TFunction, recOptions?: RECOrderAttributeOptions) {
  const tableColumns: Column<RecHoldingsTableData>[] = [
    {
      Header: t("Vintage"),
      accessor: (data: RecHoldingsTableData) => data.vintage,
      Cell: ({ value }: { value: string }) => {
        return <TableValueWrapper value={value} />;
      },
      disableSortBy: true,
    },
    {
      Header: t("Project"),
      accessor: "project",
      Cell: ({ value }: { value: string }) => {
        return <TableValueWrapper value={t(value) || ""} />;
      },
      disableSortBy: true,
    },
    {
      Header: t("Location"),
      accessor: "location",
      Cell: ({ value }: { value: string }) => (
        <LocationValueWrapper value={value ? [value] : ["-"]} recOptions={recOptions} />
      ),
      disableSortBy: true,
    },
    {
      Header: t("Eligibility"),
      accessor: "certificationsAndEligibilities",
      Cell: ({ value }: { value: CertificationAndEliglibilityType[] }) => {
        return <EligibilityValueFormatter certificationsAndEligibilities={value} position={OrderPosition.Ask} />;
      },
      disableSortBy: true,
    },

    {
      Header: t("Fuel Type"),
      accessor: (row) => row.attributes.fuelSources,
      Cell: ({ value }: { value: string[] }) => {
        return (
          <Flex sx={{ color: "textDarker" }}>
            {!value || value.length < 1 ? (
              "-"
            ) : (
              <TableValueWrapper
                title={"Fuel Sources"}
                getIcon={getFuelSourceIcon}
                value={value}
                getToolTipText={(key: string) =>
                  recOptions?.fuelSourceOptions?.filter((x) => x.value === key)[0]?.label || ""
                }
              />
            )}
          </Flex>
        );
      },
      disableSortBy: true,
    },
    {
      Header: t("Commencement Date (COD)"),
      id: "commencementDate",
      Cell: ({ row }: { row: Row<RecHoldingsTableData> }) => {
        const commencementDate = row.original.attributes?.commencementDate ?? "-";
        return <TableValueWrapper value={commencementDate} />;
      },
      disableSortBy: true,
    },
    {
      Header: () => (
        <Flex sx={{ alignItems: "center" }}>
          <Text>{t("Balance")}</Text>
          <IconInfo
            size={5}
            color="success.800"
            sx={{ ml: 1 }}
            data-tip={t(
              "The available balance for your account may differ from the current balance because of pending transactions that have been presented against the account, but have not yet been processed. ",
            )}
          />
        </Flex>
      ),
      id: "balance",
      Cell: ({ row }: { row: Row<RecHoldingsTableData> }) => (
        <Text sx={{ color: "textDarker" }}>
          {`${new Intl.NumberFormat().format(row.original.availableBalance)} / ${new Intl.NumberFormat().format(
            row.original.currentBalance,
          )}`}
        </Text>
      ),
    },
  ];
  return tableColumns;
}
