import { pick } from "lodash";

import { demoAccount } from "../../app/container/apollo-client/demo-data";
import {
  AccountLinkedRegistriesDocument,
  AccountType,
  RegistryCode,
  RegistryLinkStatus,
} from "../../app/types/generated/graphql";

export const mockAccountLinkedRegistries = {
  request: {
    query: AccountLinkedRegistriesDocument,
  },
  result: {
    data: {
      account: pick(demoAccount, ["linkedRegistries"]),
    },
  },
};

export const mockAccountLinkedMultipleRegistriesQuery = {
  request: {
    query: AccountLinkedRegistriesDocument,
  },
  result: {
    data: {
      account: {
        id: "df30cd30-a590-446f-a93b-af9d1520c37f",
        linkedRegistries: [
          {
            id: "c167cd8b-0450-4643-8bf0-ac2e778e3735",
            registryId: "e2e2ec2d-5158-4c7b-9996-b70e36c7dec2",
            registryOrgLinkId: "MM7",
            status: RegistryLinkStatus.Registered,
            registryCode: RegistryCode.Mrets,
            accountType: AccountType.Registry,
          },
        ],
      },
    },
  },
};
