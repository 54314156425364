import { useCallback, useMemo } from "react";

import { useAppContext } from "@/app/context";
import { useAllFeatureToggleValues } from "@/app/hooks/use-feature-toggle/use-all-feature-toggle-values";
import { RegistryCode, useRegistriesQuery } from "@/app/types/generated/graphql";

import { RegistryOption, UseRegistryDropdownFn } from "./registry-dropdown.types";

export const useRegistryDropdown: UseRegistryDropdownFn = () => {
  const {
    toggles: { enableErcotIntegration },
    loading: featureFlagsLoading,
  } = useAllFeatureToggleValues();
  const { userAccountInfo, userSelections, setUserSelections } = useAppContext();
  const { loading, data: registriesData } = useRegistriesQuery();

  const linkedRegistriesOptions = useMemo(
    () =>
      (userAccountInfo?.linkedRegistries
        ?.map((linkedRegistry) => {
          if (!enableErcotIntegration && linkedRegistry.registryCode === RegistryCode.Ercot) {
            return null;
          }

          const registry = registriesData?.registries?.find((registry) => registry.id === linkedRegistry.registryId);

          return registry
            ? {
                registryId: registry.id,
                registryCode: linkedRegistry.registryCode,
                shortName: registry.shortName,
                status: linkedRegistry.status,
              }
            : null;
        })
        .filter(Boolean) ?? []) as RegistryOption[],
    [enableErcotIntegration, registriesData?.registries, userAccountInfo?.linkedRegistries],
  );

  const activeRegistry = useMemo(
    () =>
      linkedRegistriesOptions?.find((registry) => registry?.registryId === userSelections?.registry?.registryId) ??
      null,
    [linkedRegistriesOptions, userSelections?.registry?.registryId],
  );

  const onActiveRegistryChange = useCallback(
    (registryOption: RegistryOption | null) => {
      const linkedRegistry = userAccountInfo?.linkedRegistries?.find(
        (linkedRegistry) => linkedRegistry.registryId === registryOption?.registryId,
      );

      if (!linkedRegistry) return;

      setUserSelections({
        ...userSelections,
        registry: linkedRegistry,
      });
    },
    [setUserSelections, userAccountInfo?.linkedRegistries, userSelections],
  );

  return {
    loading: loading && featureFlagsLoading,
    registries: linkedRegistriesOptions,
    activeRegistry,
    onActiveRegistryChange,
  };
};
