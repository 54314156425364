import { useMemo } from "react";

import { useGetLocationTexts } from "@/app/hooks/use-get-locations-text";

import { TableValueWrapper } from "../table-value-wrapper";
import { LocationValueWrapperProps } from "./location-value-wrapper.types";

export const LocationValueWrapper = ({
  value,
  csv = false,
  maxContent = 1,
  containerSx,
  recOptions,
}: LocationValueWrapperProps) => {
  // In the modal, `auth` is null, so `recOptions` is provided during table rendering for getting country name.
  const { getLocationTextsWithCountry } = useGetLocationTexts({ options: recOptions });

  const locationValue = useMemo(() => getLocationTextsWithCountry(value), [value, getLocationTextsWithCountry]);

  return (
    <TableValueWrapper
      value={locationValue}
      title={"Locations"}
      csv={csv}
      maxContent={maxContent}
      containerSx={containerSx}
    />
  );
};
