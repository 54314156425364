import { useOktaAuth } from "@okta/okta-react";
import { lazy, Suspense, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { AttributesTooltip, Layout, LoadingOverlayIndicator, TermsAndConditionsModal } from "../components";
import notifications from "../container/notifications";
import { useAppContext } from "../context/app-context";
import { FeatureToggleKey } from "../hooks";
import { useAccountInfo } from "../hooks/use-account-info";
import { useHasPermissions } from "../hooks/use-has-permission";
import { useOktaRoles } from "../hooks/use-okta-roles";
import { isOnboardingOrRetryUser } from "../lib/account-service-helpers";
import { Home } from "../pages/public/home";
import { RegistryCode, useFewAccountDetailsQuery } from "../types/generated/graphql";
import { flattenPrivateRoutes, PrivateRoute } from "./routes/private-routes";
import { PublicRoute, publicRoutes } from "./routes/public-routes";
const Onboarding = lazy(() => import("../pages/public/signup/onboarding/onboarding"));

export const AppRouter = () => {
  const { authState } = useOktaAuth();
  const { userRoles } = useOktaRoles();

  const { userAccountInfo, userSelections, setUserAccountInfo, setUserSelections } = useAppContext();
  const isAuthenticated = authState?.isAuthenticated;
  const accountInfo = useAccountInfo({
    skipQuery: !isAuthenticated,
  });

  useEffect(() => {
    if (accountInfo.dataReady && !userAccountInfo?.loaded) {
      setUserAccountInfo({
        id: accountInfo.id,
        tenantId: accountInfo.tenantId,
        firstName: accountInfo.firstName,
        lastName: accountInfo.lastName,
        email: accountInfo.email,
        status: accountInfo.status,
        roles: userRoles,
        accountType: accountInfo.type,
        linkedRegistries: accountInfo.linkedRegistries,
        loaded: true,
      });
    }

    if (accountInfo.dataReady && !userSelections) {
      const mretsReg = accountInfo.linkedRegistries?.find((reg) => reg.registryCode === RegistryCode.Mrets);

      setUserSelections({
        registry: mretsReg ?? accountInfo.linkedRegistries?.[0],
      });
    }
  }, [accountInfo, userRoles, userAccountInfo, userSelections, setUserAccountInfo, setUserSelections]);

  return (
    <Routes>
      <Route path="/" element={<RootRouteRender />} />
      {publicRoutes.map((route) => (
        <Route path={route.path} key={route.path} element={<PublicRoute>{route.render()}</PublicRoute>} />
      ))}
      {flattenPrivateRoutes.map((route) => (
        <Route
          path={route.path}
          key={route.path}
          element={
            <PrivateRoute guard={route.guard}>
              <Layout>
                {/* Fallback to match layout to avoid flickering */}
                <Suspense fallback={<Layout></Layout>}>{route.render()}</Suspense>
                <AttributesTooltip />
              </Layout>
            </PrivateRoute>
          }
        />
      ))}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export const RootRouteRender = () => {
  const { authState } = useOktaAuth();
  const { isRequiredFeatureTogglesEnabled } = useHasPermissions({
    skipQuery: true,
  });

  // TODO: Cleanup logic to check feature toggle after Forward Trade feature is enabled, defaults to spot trade
  const forwardTradeFeatureToggleEnabled = isRequiredFeatureTogglesEnabled([FeatureToggleKey.EnableForwardTrade]);
  const marketRoute = forwardTradeFeatureToggleEnabled ? "/market/spot-trade" : "/market";

  const { data, loading, error } = useFewAccountDetailsQuery({
    skip: !authState?.isAuthenticated,
    // setting fetchPolicy to "no-cache" fixes the bug in the card TX-2614, as a side-effect causes 2 API calls to fetch account when logging in
    fetchPolicy: "no-cache",
    onError: (error) => {
      error instanceof Error &&
        notifications.error({
          description: error?.message,
        });
    },
  });

  if (error) {
    throw error;
  }

  //If not authenticated show Login Page
  if (!authState?.isAuthenticated) return <Home />;

  //If authenticated => query is hit and loading is true, Show loading Overlay
  if (loading) return <LoadingOverlayIndicator />;

  //If account status is onboarding or retry, go To Signup
  if (isOnboardingOrRetryUser(data?.account?.status))
    return (
      <Suspense fallback={<LoadingOverlayIndicator />}>
        <Onboarding />
      </Suspense>
    );

  // If User has not accepted the terms and conditions
  if (
    !data?.account?.termsAcceptedDate ||
    !data?.account?.rulesAcceptedDate ||
    !data?.account?.privacyPolicyAcceptedDate
  ) {
    return <TermsAndConditionsModal />;
  }

  //Else Navigate to Market
  return <Navigate to={marketRoute} replace />;
};
