import { Font, StyleSheet } from "@react-pdf/renderer";

Font.register({
  family: "CentraNo2",
  fonts: [
    { src: "/assets/fonts/CentraNo2-Light/CentraNo2-Light.woff", fontWeight: 300 },
    {
      src: "/assets/fonts/CentraNo2-LightItalic/CentraNo2-LightItalic.woff",
      fontWeight: 300,
      fontStyle: "italic",
    },
    { src: "/assets/fonts/CentraNo2-Medium/CentraNo2-Medium.woff", fontWeight: 500 },
  ],
});

Font.register({
  family: "MintGroteskV08-Extra",
  fonts: [
    {
      src: "/assets/fonts/MintGroteskV08-ExtraBold/MintGroteskV08-ExtraBold.woff",
      fontWeight: "bold",
      fontStyle: "normal",
    },
  ],
});

Font.register({
  family: "MintGroteskV08",
  fonts: [
    {
      src: "/assets/fonts/MintGroteskV08-Bold/MintGroteskV08-Bold.woff",
      fontWeight: "bold",
      fontStyle: "normal",
    },
    {
      src: "/assets/fonts/MintGroteskV08-Black/MintGroteskV08-Black.woff",
      fontWeight: 700,
      fontStyle: "normal",
    },
    {
      src: "/assets/fonts/MintGroteskV08-Heavy/MintGroteskV08-Heavy.woff",
      fontWeight: 900,
      fontStyle: "normal",
    },
    {
      src: "/assets/fonts/MintGroteskV08-Medium/MintGroteskV08-Medium.woff",
      fontStyle: "normal",
      fontWeight: 500,
    },
    {
      src: "/assets/fonts/MintGroteskV08-Light/MintGroteskV08-Light.woff",
      fontWeight: 300,
      fontStyle: "normal",
    },
    {
      src: "/assets/fonts/MintGroteskV08-Regular/MintGroteskV08-Regular.woff",
      fontWeight: "normal",
      fontStyle: "normal",
    },
    {
      src: "/assets/fonts/MintGroteskV08-Thin/MintGroteskV08-Thin.woff",
      fontWeight: 100,
      fontStyle: "normal",
    },
  ],
});

Font.register({
  family: "Inter",
  fonts: [
    {
      src: "/assets/fonts/Inter-Regular/Inter-Regular.woff",
      fontWeight: "normal",
      fontStyle: "normal",
    },
    {
      src: "/assets/fonts/Inter-Bold/Inter-Bold.woff",
      fontWeight: "bold",
      fontStyle: "normal",
    },
  ],
});

export const colors = {
  gradientYellow: "#F4D34C",
  gradientPurple: "#C5A1E9",
  backgroundDark: "#e6e6e6",
  tableHeaderBackground: "#27D9E5",
  textLight: "#4d5858",
  text: "#000000",
  border: "#4d5858",
};

export const styles = StyleSheet.create({
  h1: {
    fontSize: 24,
    color: colors.textLight,
    fontWeight: "bold",
    fontFamily: "Inter",
  },
  h2: {
    fontSize: 12,
    color: colors.text,
    fontWeight: 500,
    fontFamily: "CentraNo2",
  },
  h3: {
    fontSize: 11,
    color: colors.textLight,
    fontWeight: "bold",
  },
  text: {
    color: colors.text,
    fontSize: 10,
    paddingBottom: 4,
    fontWeight: "normal",
    fontFamily: "CentraNo2",
  },
  tableH2: {
    fontSize: 10,
    fontWeight: "bold",
    backgroundColor: colors.backgroundDark,
  },
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderStyle: "solid",
    borderColor: colors.border,
    borderWidth: 1.5,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    marginBottom: 16,
    marginTop: 20,
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: colors.tableHeaderBackground,
    fontSize: 14,
    color: colors.text,
    fontWeight: "extrabold",
    borderBottomStyle: "solid",
    borderBottomColor: colors.border,
    borderBottomWidth: 1.5,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    borderBottomStyle: "solid",
    borderBottomColor: colors.border,
    borderBottomWidth: 1.5,
  },
  tableCell: {
    width: "50%",
    padding: 4,
    borderRightStyle: "solid",
    borderRightColor: colors.border,
    borderRightWidth: 1.5,
  },
  finePrint: {
    color: colors.textLight,
    fontSize: 8,
    fontWeight: "light",
    paddingBottom: 4,
  },
});
