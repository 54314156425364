import { Box, Button, Flex, Text, Tooltip } from "@powerledger/ui-component-lib";
import currency from "currency.js";
import { TFunction } from "i18next";
import { startCase, toLower } from "lodash";
import ReactHtmlParser from "react-html-parser";
import { Column, Row } from "react-table";
import ReactTooltip from "react-tooltip";

import { ForwardTradeAttributesTooltip } from "@/app/components/forward-trade-attributes-tooltip";
import { ForwardTradeDetailsModalCustomProps } from "@/app/components/forward-trade-details-modal/forward-trade-details-modal.types";
import { ForwardTradeWithdrawCustomProps } from "@/app/components/forward-trade-withdraw-modal/forward-trade-withdraw-modal.types";
import { TableActionsButtons } from "@/app/components/table-actions-buttons";
import { TableValueWrapper } from "@/app/components/table-value-wrapper";
import { HeaderWithToolTip } from "@/app/components/tooltips";
import { AppDateFormats, formatDate } from "@/app/lib/format-date";
import { getTotalForwardTradeValue } from "@/app/lib/forward-trade-helpers";
import { TradeContractStatus, TradePosition } from "@/app/types/generated/graphql";

import { DownloadReportButtons, SupportedDocumentType } from "../components/download-report-buttons";
import { AssignRecsProps, TradeContractHistoryTableData } from "./forward-trade-history.types";
import { canShowViewAssets } from "./helpers";

export const getForwardTradeColumns = (
  t: TFunction,
  actions: {
    handleAssignRecs: (args: AssignRecsProps) => void;
    handleWithdrawRecs: (args: ForwardTradeWithdrawCustomProps) => void;
    showForwardTradeDetailsModal: (args: ForwardTradeDetailsModalCustomProps) => void;
    downloadTransactionSummary: (
      type: SupportedDocumentType,
      {
        orderId,
        orderNumber,
      }: {
        orderId: string;
        orderNumber: string;
      },
    ) => Promise<void>;
  },
) => {
  const columns: Column<TradeContractHistoryTableData>[] = [
    {
      Header: t("Order Number"),
      accessor: "tradeContractNumber",
      disableSortBy: true,
    },
    {
      Header: <HeaderWithToolTip titleText="OLD" tooltipText="Order Lodgement Date" />,
      accessor: "createdDate",
      disableSortBy: false,
      Cell: ({ value }: { value: string }) => (
        <TableValueWrapper
          value={formatDate(value, { formatStyle: AppDateFormats.AbbreviatedMonthFormat, noTZ: true })}
        />
      ),
    },
    {
      Header: <HeaderWithToolTip titleText="TSD" tooltipText="Trade Settlement Date" />,
      accessor: "tradeSettlementDate",
      disableSortBy: false,
      Cell: ({ value }: { value: string }) => (
        <TableValueWrapper
          value={formatDate(value, { formatStyle: AppDateFormats.AbbreviatedMonthFormat, noTZ: true })}
        />
      ),
    },
    {
      Header: t("Status"),
      accessor: "status",
      disableSortBy: true,
      Cell: ({ value, row }: { value: string; row: Row<TradeContractHistoryTableData> }) => {
        const { original } = row;
        return (
          <Flex sx={{ color: "textDark", alignItems: "center", whiteSpace: "nowrap" }}>
            {`${t(startCase(toLower(value)))}`}
            {original.statusMessage && (
              <Tooltip
                id={`order-${original.id}-status-tooltip`}
                content={
                  <Box
                    sx={{
                      "& > p": {
                        marginY: 1,
                      },
                    }}
                  >
                    {ReactHtmlParser(t(original.statusMessage ?? ""))}
                  </Box>
                }
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: 16,
                  height: 16,
                  marginTop: 1,
                }}
              />
            )}
          </Flex>
        );
      },
    },
    {
      Header: t("Quantity"),
      accessor: "volume",
      disableSortBy: true,
      Cell: ({ value }) => <TableValueWrapper value={value} />,
    },
    {
      Header: (
        <Box
          sx={{
            textAlign: "right",
            width: 100,
          }}
        >
          {t("Unit Price")}
        </Box>
      ),
      disableSortBy: true,
      accessor: "unitPrice",
      Cell: ({ value }: { value: string }) => {
        return (
          <Box
            sx={{
              textAlign: "right",
              color: "textDark",
              width: 100,
            }}
          >
            {currency(value || 0, { separator: ",", fromCents: true }).format()}
          </Box>
        );
      },
    },
    {
      Header: () => t("Product Attributes"),
      id: "attributes",
      Cell: ({ row }: { row: Row<TradeContractHistoryTableData> }) => {
        const { original } = row;

        const tooltipRowData = {
          attributes: {
            commencementDate: original.attributes.commencementDate,
            fuelSources: original.attributes.fuelSources,
          },
          vintages: original.formattedAttributes.vintages,
          certificationsAndEligibilities: original.formattedAttributes.certificationsAndEligibilities,
          fuelSources: original.formattedAttributes.fuelSources,
        };

        const tooltipContent = (dataTip: string) => {
          return dataTip === "rec-tooltip" ? (
            <Box sx={{ color: "text", whiteSpace: "pre-line", backgroundColor: "shadow", p: 3, borderRadius: 8 }}>
              <ForwardTradeAttributesTooltip tooltipData={tooltipRowData} />
            </Box>
          ) : null;
        };
        const vintagesText = original.formattedAttributes.vintages?.[0];
        const eligibilitiesText = original.formattedAttributes.eligibilities?.length
          ? `, ${original.formattedAttributes.eligibilities}`
          : "...";

        return (
          <>
            <ReactTooltip
              id={original.id}
              backgroundColor="shadow"
              arrowColor="#D0D5DD"
              getContent={(dataTip) => tooltipContent(dataTip)}
              padding="0"
            />
            <Text
              sx={{
                display: "inline-block",
                width: "120px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
              data-tip="rec-tooltip"
              data-for={original.id}
            >
              {vintagesText}
              {eligibilitiesText}
            </Text>
          </>
        );
      },
    },
    {
      Header: () => t("Order Type"),
      accessor: "position",
      disableSortBy: true,
      Cell: ({ value }: { value: string }) => (
        <Box
          sx={{
            variant: value === TradePosition.Ask ? "chips.error" : "chips.info",
            width: "40px",
          }}
        >
          {value}
        </Box>
      ),
    },
    {
      Header: () => t("Total"),
      id: "totaltradevalue",
      disableSortBy: true,
      Cell: ({ row }: { row: Row<TradeContractHistoryTableData> }) => {
        const { original } = row;
        const tradeValue = getTotalForwardTradeValue(original.unitPrice, original.volume).value;
        return <Box>{currency(tradeValue || 0, { separator: ",", fromCents: true }).format()}</Box>;
      },
    },
    {
      Header: () => t("Actions"),
      id: "forwardActions",
      Cell: ({ row }: { row: Row<TradeContractHistoryTableData> }) => {
        const { status, position, tradeContractNumber, id, volume, registryCode, attributes } = row.original;

        const actionAssignRecs = () =>
          actions.handleAssignRecs({
            tradeContractNumber,
            tradeContractId: id,
            askTradeVolume: volume,
            registryCode,
            tradeAttributes: attributes,
            status,
          });

        const isPositionAsk = position === TradePosition.Ask;

        const showAssignAssets = isPositionAsk && status === TradeContractStatus.Contracted;

        const showViewAssets = isPositionAsk && canShowViewAssets(status);

        const actionLists = [
          {
            actionName: "See Details",
            onAction: () => actions.showForwardTradeDetailsModal({ tradeContract: row.original }),
            showAction: true,
          },
          {
            actionName: "Withdraw",
            onAction: () => actions.handleWithdrawRecs({ tradeID: id }),
            showAction: status === TradeContractStatus.Open,
          },
          {
            actionName: "Assign RECs",
            onAction: actionAssignRecs,
            showAction: showAssignAssets,
          },
          {
            actionName: "View RECs",
            onAction: actionAssignRecs,
            showAction: showViewAssets,
          },
          {
            actionName: "Download CSV",
            onAction: async () => {
              await actions.downloadTransactionSummary("csv", {
                orderId: row.original.id,
                orderNumber: row.original.tradeContractNumber,
              });
            },
            showAction: [TradeContractStatus.Completed, TradeContractStatus.Withdrawn].includes(status),
          },
          {
            actionName: "Download PDF",
            onAction: async () => {
              await actions.downloadTransactionSummary("pdf", {
                orderId: row.original.id,
                orderNumber: row.original.tradeContractNumber,
              });
            },
            showAction: [TradeContractStatus.Completed, TradeContractStatus.Withdrawn].includes(status),
          },
        ];

        const filteredActionLists = actionLists
          .filter((action) => action.showAction)
          .map(({ actionName, onAction }) => {
            if (actionName.startsWith("Download")) {
              return (
                <DownloadReportButtons
                  key={actionName}
                  actionName={actionName}
                  onAction={onAction as () => Promise<void>}
                />
              );
            }

            return (
              <Button
                key={actionName}
                variant="pill.compactSecondary"
                sx={{ py: 0, px: 3, height: "30px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                onClick={onAction}
              >
                {t(actionName)}
              </Button>
            );
          });

        return <TableActionsButtons actions={filteredActionLists} />;
      },
    },
  ];
  return columns;
};
