import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useOktaRoles } from "@/app/hooks/use-okta-roles";
import { UserRoles } from "@/app/lib/role-based-access-control";

import { KEY_PLAID_LINK_TOKEN, usePlaidLinkModal } from "../../hooks/use-plaid-link-modal";
import { useCompanyDetailsStatusIcon } from "./company-details-tab/useCompanyDetailsStatusIcon";
import { usePaymentServicesStatusIcon } from "./payment-services-tab/select-provider/use-payment-services-status-icon";
import { useRegistrySettingsStatusIcon } from "./registry-tab/use-registry-settings-status-icon";
import { SettingsTabs, UseSettingsFn } from "./settings.types";

export const useSettings: UseSettingsFn = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(SettingsTabs.details);

  const paymentServicesIcon = usePaymentServicesStatusIcon();
  const registryIcon = useRegistrySettingsStatusIcon();
  const companyDetailsIconName = useCompanyDetailsStatusIcon();

  const { userRoles } = useOktaRoles();

  const [receivedRedirectUri, setReceivedRedirectUri] = useState("");

  useEffect(() => {
    const { search } = location;
    const tab = new URLSearchParams(search).get("tab") as unknown as SettingsTabs;
    const redirectUri = new URLSearchParams(search).get("oauth_state_id");
    if (redirectUri) {
      setReceivedRedirectUri(window.location.href);
    } else setReceivedRedirectUri("");
    setCurrentTab(tab || SettingsTabs.details);
  }, [location]);

  usePlaidLinkModal({
    onLinkAccountSuccess: () => {
      localStorage.removeItem(KEY_PLAID_LINK_TOKEN);
      setCurrentTab(SettingsTabs.paymentServices);
    },
    receivedRedirectUri,
  });

  const switchTab = useCallback(
    (tab: string) => {
      const params = new URLSearchParams({ tab });
      navigate(
        { pathname: location.pathname, search: params.toString() },
        {
          replace: true,
        },
      );
    },
    [navigate, location.pathname],
  );

  const isAdmin = useMemo(() => userRoles.includes(UserRoles.RoleAdmin), [userRoles]);

  return {
    switchTab,
    currentTab,
    isAdmin,
    companyDetailsIconName,
    paymentServicesIcon,
    registryIcon,
  };
};
