import { FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import notifications from "@/app/container/notifications";
import { useAppContext } from "@/app/context";
import { useRecOptions } from "@/app/hooks/use-rec-options";
import { formatAttributes } from "@/app/lib/format-attributes";
import {
  PaginatedHoldingsDocument,
  RecAssetAttributes,
  useExportCommoditiesByVolumeMutation,
  usePaginatedHoldingsQuery,
} from "@/app/types/generated/graphql";

import { RecHoldingsTableData } from "../rec-holdings";
import { ExportRecsFormValues, ExportRecsLifeCycle, useExportRecsFn } from "./export-recs.types";

export const getInitialExportRecsValues = (recData: RecHoldingsTableData[], actionVolume = 0) => ({
  values: recData.map((data) => ({
    ...data,
    actionVolume,
  })),
});

export const useExportRecs: useExportRecsFn = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [exportCommoditiesByVolume] = useExportCommoditiesByVolumeMutation({
    refetchQueries: [PaginatedHoldingsDocument],
  });

  const selectedRecs = useMemo(() => location.state?.selectedRecs ?? [], [location.state]);

  const [currentStep, setCurrentStep] = useState(ExportRecsLifeCycle.Form);

  const [savedData, setSavedData] = useState<ExportRecsFormValues>();

  const { recOptions, loading: recOptionsLoading } = useRecOptions();

  const { userSelections } = useAppContext();

  const { loading: queryLoading, data: holdingsData } = usePaginatedHoldingsQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        offSetPaginationInfo: {
          offset: 0,
          limit: selectedRecs.length,
        },
        where: {
          ids: selectedRecs,
        },
      },
    },
  });

  const loading = queryLoading;

  useEffect(() => {
    if (!selectedRecs?.length || (!loading && !holdingsData?.paginatedHoldings?.holdings?.length))
      navigate("/recs/holdings");
  }, [selectedRecs, loading, navigate, holdingsData?.paginatedHoldings?.holdings]);

  const tableData: RecHoldingsTableData[] = useMemo(() => {
    return recOptionsLoading || !recOptions
      ? []
      : holdingsData?.paginatedHoldings?.holdings?.map((recHolding) => {
          const attributes = recHolding.attributes as RecAssetAttributes;
          return {
            ...recHolding,
            attributes,
            ...(recHolding?.attributes &&
              formatAttributes({
                attributes,
                options: recOptions,
              })),
          };
        }) || [];
  }, [recOptionsLoading, recOptions, holdingsData?.paginatedHoldings?.holdings]);

  const onProceed = useCallback(
    async (values: ExportRecsFormValues, actions?: FormikHelpers<ExportRecsFormValues>) => {
      if (currentStep === ExportRecsLifeCycle.Form) {
        setCurrentStep(ExportRecsLifeCycle.Summary);
        setSavedData(values);
      } else if (currentStep === ExportRecsLifeCycle.Summary) {
        try {
          await exportCommoditiesByVolume({
            variables: {
              exportCommoditiesByVolumeInput: {
                commodityDetails: values.values.map((val) => ({
                  holdingId: val.id,
                  volume: Number(val.actionVolume),
                })),
                registryCode: userSelections?.registry?.registryCode,
              },
            },
          });
          setCurrentStep(ExportRecsLifeCycle.Success);
        } catch (e) {
          e instanceof Error &&
            notifications.error({
              description: t(e?.message),
            });
          actions?.setSubmitting(false);
        }
      } else {
        navigate("/recs/export-history");
      }
    },
    [currentStep, navigate, exportCommoditiesByVolume, t, userSelections?.registry?.registryCode],
  );

  const onGoBack = useCallback(() => {
    if (currentStep === ExportRecsLifeCycle.Summary) {
      setCurrentStep(ExportRecsLifeCycle.Form);
    } else navigate("/recs/holdings");
  }, [currentStep, navigate]);

  const initialValues: ExportRecsFormValues = useMemo(
    () => (savedData?.values?.length ? savedData : getInitialExportRecsValues(tableData)),
    [savedData, tableData],
  );

  const formikProps = useMemo(
    () => ({
      initialValues,
    }),
    [initialValues],
  );

  return {
    tableData,
    loading,
    recOptions,
    currentStep,
    formikProps,
    onProceed,
    onGoBack,
  };
};
