import { NetworkStatus } from "@apollo/client";
import { cloneDeep } from "lodash";

import { RegistryCode, useFewAccountDetailsQuery } from "@/app/types/generated/graphql";

export const useAccountInfo = (args?: { skipQuery?: boolean }) => {
  const { data, loading, error, networkStatus } = useFewAccountDetailsQuery({ skip: args?.skipQuery });
  const accountData = cloneDeep(data?.account);

  if (error) {
    throw error;
  }

  // Setting default values for each linked registry as fallback
  accountData?.linkedRegistries.forEach((linkedRegistry) => {
    if (!linkedRegistry.accountType) {
      linkedRegistry.accountType = accountData?.type;
    }

    if (!linkedRegistry.registryCode) {
      linkedRegistry.registryCode = RegistryCode.Mrets;
    }
  });

  return {
    loading: loading,
    dataReady: !loading && networkStatus === NetworkStatus.ready && !!data,
    ...accountData,
  };
};
