import * as Yup from "yup";

import { AccountType } from "@/app/types/generated/graphql";

/**
 * Validation Schema for Linked Registry
 */
export const registrySchema = Yup.object().shape({
  status: Yup.string().oneOf(["REGISTERED"]),
});

export const multipleLinkRegistrySchema = Yup.object().shape({
  registryId: Yup.string().required("Required"),
  type: Yup.string().nullable().required("Required"),
  accountNumber: Yup.string().when("type", {
    is: AccountType.Registry,
    then: Yup.string().required("Required"),
    otherwise: Yup.string().nullable().notRequired(),
  }),
  nrmTermsAccepted: Yup.boolean().when("type", {
    is: AccountType.NonRegistry,
    then: Yup.boolean().oneOf([true], "You must accept the terms"),
    otherwise: Yup.boolean().notRequired(),
  }),
});

export const linkRegistrySchema = Yup.object().shape({
  registryId: Yup.string().required("Required"),
  accountNumber: Yup.string().notRequired(),
});
