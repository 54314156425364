import { Modal } from "@powerledger/ui-component-lib";

import { OrderLifeCycle } from "../common";
import { AcceptForwardTradeOrderModalProps } from "./accept-forward-trade-order-modal.types";
import { ForwardTradeSummaryView } from "./forward-trade-summary-view";
import { useAcceptForwardTradeOrderModal } from "./use-accept-forward-trade-order-modal";

export const AcceptForwardTradeOrderModal: React.FC<AcceptForwardTradeOrderModalProps> = ({
  tradeContract,
  visible,
  closeModal,
}) => {
  const logic = useAcceptForwardTradeOrderModal({ tradeContract, closeModal });
  return (
    <Modal
      visible={visible}
      onCancel={closeModal}
      modalMaxWidth="1000px"
      containerOverflow="auto"
      sx={{
        ...(logic.currentStep === OrderLifeCycle.Form
          ? {
              overflow: "auto",
              maxHeight: "inherit",
            }
          : {}),
        color: logic.currentStep === OrderLifeCycle.Success ? "background" : "text",
      }}
    >
      <ForwardTradeSummaryView {...logic} />
    </Modal>
  );
};
