import { createContext, useContext, useEffect, useState } from "react";

import { AppContextType, AppUserAccountInfo, AppUserSelections } from "./app-context.types";

export const AppContext = createContext<AppContextType>({
  userAccountInfo: null,
  setUserAccountInfo: () => {},
  userSelections: null,
  setUserSelections: () => {},
});

export const AppContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userAccountInfo, setUserAccountInfo] = useState<AppUserAccountInfo | null>(null);
  const [userSelections, setUserSelections] = useState<AppUserSelections | null>(null);

  useEffect(() => {
    if (userSelections?.registry?.accountType) {
      setUserAccountInfo((userAccountInfo) => {
        if (!userAccountInfo) return null;

        return {
          ...userAccountInfo,
          accountType: userSelections?.registry?.accountType,
        };
      });
    }
  }, [userSelections?.registry?.accountType]);

  return (
    <AppContext.Provider
      value={{
        userAccountInfo,
        setUserAccountInfo,
        userSelections,
        setUserSelections,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
