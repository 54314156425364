import { Box, Flex, Text } from "@powerledger/ui-component-lib";
import currency from "currency.js";
import { useTranslation } from "react-i18next";

import { getTotalMarketValue, getTotalValue, getTransactionFee } from "@/app/lib/order-helpers";
import { OrderPosition, TradePosition } from "@/app/types/generated/graphql";

type OrderSummaryProps = Readonly<{
  currencySymbol: string;
  title: string;
  unitPrice: number;
  volume: number;
  orderPosition: OrderPosition | TradePosition;
  fee: number;
}>;

export default function OrderSummary({
  currencySymbol,
  title,
  unitPrice,
  volume,
  orderPosition,
  fee,
}: OrderSummaryProps) {
  const { t } = useTranslation();

  const offerType = orderPosition === OrderPosition.Ask ? "Offer" : "Bid";

  return (
    <>
      <Box sx={{ my: 3, fontFamily: "MintGroteskV08", fontWeight: "bold", fontSize: 3 }}>{t(title)}</Box>
      <Box sx={{ fontSize: 1 }}>
        <Flex sx={{ justifyContent: "space-between", mt: 1 }}>
          <Text>{t("Product Quantity")}</Text>
          {/* format 1000 -> 1,000 */}
          <Text>{Number(volume).toLocaleString("en-US")}</Text>
        </Flex>
        <Flex sx={{ justifyContent: "space-between", mt: 1 }}>
          <Text>{t("{{offerType}} Price per Unit", { offerType })}</Text>
          <Text>{currencySymbol + currency(unitPrice).value.toLocaleString("en-US")}</Text>
        </Flex>
        <Flex sx={{ justifyContent: "space-between", mt: 1 }}>
          <Text>{t(`Total {{offerType}} Order Value`, { offerType })}</Text>
          <Text>{currencySymbol + getTotalMarketValue(unitPrice, volume).value.toLocaleString("en-US")}</Text>
        </Flex>
        <Flex
          sx={{
            mt: 1,
            justifyContent: "space-between",
            pb: 3,
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
            borderBottomColor: "textDarker",
          }}
        >
          <Text>
            {t("Transaction Fee ({{value}}%)", {
              value: fee * 100,
            })}
          </Text>
          <Text>
            {unitPrice & volume && offerType === "Bid" ? "+" : ""}
            {unitPrice & volume && offerType === "Offer" ? "-" : ""}
            {currencySymbol + getTransactionFee(unitPrice, volume, fee).value.toLocaleString("en-US")}
          </Text>
        </Flex>
        <Flex
          sx={{
            justifyContent: "space-between",
            pt: 3,
          }}
        >
          <Text>{t("Total Order Value")}</Text>
          <Text>
            {currencySymbol + getTotalValue(unitPrice, volume, orderPosition, fee).value.toLocaleString("en-US")}
          </Text>
        </Flex>
      </Box>
    </>
  );
}
