import { useState } from "react";

import { useAllFeatureToggleValues } from "@/app/hooks/use-feature-toggle/use-all-feature-toggle-values";

import { LinkedRegistries } from "./linked-registries/linked-registries";

export const RegistryTab = () => {
  const [openForm, setOpenForm] = useState(false);
  const {
    toggles: { enableMultipleRegistry },
  } = useAllFeatureToggleValues();
  return (
    <LinkedRegistries openForm={openForm} setOpenForm={setOpenForm} hideLinkRegistryButton={enableMultipleRegistry} />
  );
};
