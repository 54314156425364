import { Button } from "@powerledger/ui-component-lib";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { handleError } from "@/app/components/pdf-components/react-pdf-helpers";

export type SupportedDocumentType = "csv" | "pdf";

export const DownloadReportButtons = ({
  actionName,
  onAction,
}: {
  actionName: string;
  onAction: () => Promise<void>;
}) => {
  const { t } = useTranslation();
  const [downloading, setDownloading] = useState(false);

  const triggerAction = async () => {
    try {
      setDownloading(true);
      await onAction();
    } catch (error) {
      handleError(error);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <Button
      variant="pill.compactSecondary"
      sx={{ py: 0, px: 3, height: "30px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
      onClick={triggerAction}
      disabled={downloading}
    >
      {t(actionName)}
    </Button>
  );
};
