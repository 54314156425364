import { Box, Button, Flex, IconInfo, Table } from "@powerledger/ui-component-lib";
import currency from "currency.js";
import { format } from "date-fns";
import { FC, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "react-table";

import { EligibilityValueFormatter } from "@/app/components/eligibility-value-formatter";
import { LocationValueWrapper } from "@/app/components/location-value-wrapper/location-value-wrapper";
import { RefreshIconButton } from "@/app/components/refresh-icon-button";
import { TableValueWrapper } from "@/app/components/table-value-wrapper";
import { CertificationAndEliglibilityType } from "@/app/lib/format-attributes";
import { getFuelSourceIcon } from "@/app/lib/get-fuel-source-icon";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";
import { ModalContext } from "@/app/modal-provider/modal-context";
import { AccountStatus, AccountType, OrderPosition } from "@/app/types/generated/graphql";

import { MarketTableData } from "../../market.types";
import { MarketTableProps } from "./market-table.types";

export const MarketTable: FC<MarketTableProps> = ({
  recOptions,
  reloadMarketOrders,
  pageInfo,
  fetchData,
  loading,
  tableData,
  showAllAttributes,
  accountData,
}) => {
  const { t } = useTranslation();
  const { showCreateCounterOfferModal } = useContext(ModalContext);
  const tableColumns = useMemo<Column<MarketTableData>[]>(() => {
    return [
      {
        Header: t("Vintage"),
        disableSortBy: true,
        accessor: (row) => row.vintages,
        Cell: ({ value }: { value: string }) => {
          return <TableValueWrapper value={value} csv title="Vintages" containerSx={{ minWidth: 80 }} />;
        },
      },
      {
        Header: t("Eligibility"),
        accessor: "certificationsAndEligibilities",
        disableSortBy: true,
        Cell: ({ value, row }: { row: Row<MarketTableData>; value: CertificationAndEliglibilityType[] }) => {
          return <EligibilityValueFormatter certificationsAndEligibilities={value} position={row.original.position} />;
        },
      },
      {
        Header: t("Project"),
        accessor: "projects",
        Cell: ({ row, value }: { row: Row<MarketTableData>; value: string | string[] }) => {
          const projectValue =
            value || value?.length ? value : row.original.position === OrderPosition.Bid ? "Any" : "-";
          return <TableValueWrapper value={projectValue} csv title={"Projects"} containerSx={{ minWidth: 100 }} />;
        },
        disableSortBy: true,
      },
      {
        Header: t("Fuel Type"),
        accessor: (row) => row.attributes.fuelSources,
        Cell: ({ value, row }: { row: Row<MarketTableData>; value: string[] }) => {
          return (
            <Flex sx={{ color: "textDark" }}>
              {!value || value.length < 1 ? (
                row.original.position === OrderPosition.Bid ? (
                  "Any"
                ) : (
                  "-"
                )
              ) : (
                <TableValueWrapper
                  getIcon={getFuelSourceIcon}
                  title="Fuel Sources"
                  value={value}
                  getToolTipText={(key: string) =>
                    recOptions?.fuelSourceOptions.filter((x) => x.value === key)[0]?.label || ""
                  }
                />
              )}
            </Flex>
          );
        },
        disableSortBy: true,
      },

      {
        Header: (
          <Box
            sx={{
              textAlign: "right",
              width: "100px",
            }}
          >
            {t("Price USD")}
          </Box>
        ),
        id: "unitPrice",
        width: "100px",
        accessor: "price.amount" as keyof Pick<MarketTableData, "price">,
        Cell: ({ value }: { value: string }) => {
          return (
            <Box
              sx={{
                textAlign: "right",
                width: "120px",
                color: "textDark",
                px: 2,
              }}
            >
              {currency(value || 0, { separator: ",", fromCents: true }).format()}
            </Box>
          );
        },
      },
      {
        Header: () => t("Quantity"),
        accessor: "currentVolume",
        Cell: ({ value }) => <TableValueWrapper value={value} />,
      },
      {
        Header: () => t("Order Type"),
        accessor: "position",
        Cell: ({ value }) => {
          return (
            <Box
              sx={{
                variant: value === OrderPosition.Ask ? "chips.error" : "chips.info",
                width: "40px",
              }}
            >
              {value}
            </Box>
          );
        },
      },
      {
        Header: t("Commencement Date (COD)"),
        id: "commencementDate",
        Cell: ({ row }: { row: Row<MarketTableData> }) => {
          const commencementDate = row.original.attributes?.commencementDate;
          const displayValue = commencementDate ? format(new Date(commencementDate), "P") : "N/A";
          return <TableValueWrapper value={displayValue} />;
        },
        disableSortBy: true,
      },
      {
        Header: t("Location"),
        accessor: "locations",
        Cell: ({ value }: { value?: string[] }) => {
          return <LocationValueWrapper value={value ?? []} csv containerSx={{ minWidth: 110 }} />;
        },
        disableSortBy: true,
      },
      ...(accountData?.type === AccountType.Registry && accountData.registryCode === "MRETS"
        ? [
            {
              Header: t("Action"),
              id: "action",
              Cell: ({ row }: { row: Row<MarketTableData> }) => {
                const { position, allowCounterOffers, canCounter } = row.original;
                const { type, status } = accountData || {};

                const isActiveRegistry = type === AccountType.Registry && status === AccountStatus.Active;
                const isOrderPositionAsk = position === OrderPosition.Ask;
                const canPerformCounter = isActiveRegistry && isOrderPositionAsk && allowCounterOffers && canCounter;

                return canPerformCounter ? (
                  <Button
                    variant="pill.compact"
                    sx={{ py: 1, minWidth: "110px" }}
                    onClick={() =>
                      showCreateCounterOfferModal({
                        counteredTableData: row.original,
                        tenantID: accountData?.tenantId,
                        counterFromID: accountData?.id,
                      })
                    }
                  >
                    {t("Counter")}
                  </Button>
                ) : (
                  isOrderPositionAsk && (
                    <Button
                      variant="pill.compactSecondary"
                      sx={{
                        display: "flex",
                        gap: 1,
                        py: 1,
                        minWidth: "110px",
                        ":disabled": {
                          opacity: 0.6,
                          cursor: "help",
                        },
                      }}
                      disabled={!canPerformCounter}
                      data-tip={t(
                        "You cannot submit a counter offer for this ask. The seller has either disabled this option, or you have already posted a counter offer.",
                      )}
                    >
                      {t("Counter")}
                      <IconInfo
                        noSvgPositioning
                        color="primary.700"
                        sx={{
                          width: "18px",
                          height: "18px",
                        }}
                      />
                    </Button>
                  )
                );
              },
            },
          ]
        : []),
    ];
  }, [recOptions?.fuelSourceOptions, t, accountData, showCreateCounterOfferModal]);

  return (
    <>
      <Flex sx={{ alignItems: "center", mb: 2 }}>
        <Flex sx={{ ml: "auto" }}>
          <RefreshIconButton loading={loading} fetchData={reloadMarketOrders} />
        </Flex>
      </Flex>
      <Table
        loading={loading}
        showScrollArrows
        manualPagination
        columns={tableColumns}
        dataSource={tableData}
        initialState={
          showAllAttributes
            ? {}
            : {
                hiddenColumns: ["locations", "commencementDate"],
              }
        }
        fetchData={fetchData}
        pageInfo={pageInfo}
        translation={getTableTranslation(t)}
      />
    </>
  );
};
