import {
  Box,
  Button,
  Flex,
  getColor,
  IconLoading,
  SquareBlock,
  Text,
  Tooltip,
  useTheme,
} from "@powerledger/ui-component-lib";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

import { registryLogoMap } from "@/app/components/layout/registry-modal/registry-form-view";
import { getSupportEmail } from "@/app/lib/get-support-email";
import { RegistryLinkStatus } from "@/app/types/generated/graphql";

import { LinkedRegistryBlockViewProps } from "./linked-registry-block.types";

export const LinkedRegistryBlockView: FC<LinkedRegistryBlockViewProps> = ({
  loadingRegistries,
  loading,
  LinkedAccountStateIcon,
  registryData,
  linkingStatusText,
  registry,
  isPersonalRegistry,
  showRegistryModal,
  refetchRegistries,
}) => {
  const supportEmail = getSupportEmail();
  const { theme } = useTheme();
  const { t } = useTranslation();

  return loadingRegistries || loading ? (
    <IconLoading />
  ) : (
    <SquareBlock sx={{ width: ["100%", null, null, "50%"], maxWidth: 300, px: 2 }}>
      <>
        {!isPersonalRegistry && (
          <ReactTooltip
            id={`registry-${registry.id}-account-type`}
            backgroundColor={getColor(theme, "shadow")}
            getContent={() => (
              <Box sx={{ maxWidth: 300, color: "text", whiteSpace: "pre-line" }}>
                {t("You are using the market operator's account to buy and retire RECs")}
              </Box>
            )}
          />
        )}
        <Box sx={{ width: "80%" }} data-for={`registry-${registry.id}-account-type`} data-tip="rec-tooltip">
          {registryData?.code && registryLogoMap[registryData.code]}
        </Box>

        {isPersonalRegistry && (
          <Flex sx={{ alignItems: "center" }}>
            {LinkedAccountStateIcon && <LinkedAccountStateIcon size={6} sx={{ ml: "auto", flexShrink: 0 }} />}
            <Text sx={{ color: "textDarker", fontSize: 1 }}>{linkingStatusText}</Text>
          </Flex>
        )}

        {isPersonalRegistry && registry.status === RegistryLinkStatus.Registered && (
          <Flex sx={{ mt: 2, alignItems: "center" }}>
            <Text sx={{ color: "textDarker", fontSize: 0, letterSpacing: 1 }}>{t("UNLINK")}</Text>
            <Tooltip
              id={`registry-${registry.id}-unlink`}
              delayHide={500}
              content={
                <Trans
                  i18nKey="<p>To unlink your {{registryCode}} account, please contact us at <Link>{{supportEmail}}</Link> for assistance</p>"
                  components={{
                    Link: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a style={{ color: getColor(theme, "accentLight") }} href={`mailto:${supportEmail}`} />
                    ),
                  }}
                  values={{
                    supportEmail,
                    registryCode: registryData?.code,
                  }}
                >
                  <></>
                </Trans>
              }
              sx={{
                ml: 1,
                mb: -2,
                svg: {
                  width: 20,
                  height: 20,
                  path: {
                    fill: "primary.500",
                  },
                },
              }}
            />
          </Flex>
        )}
        {isPersonalRegistry && registry.status !== RegistryLinkStatus.Registered && (
          <Flex sx={{ justifyContent: "center", width: "100%", mt: 2 }}>
            {registry.status === RegistryLinkStatus.Pending && (
              <Button
                variant="text"
                sx={{ color: "primary", fontSize: 0, letterSpacing: 1, fontFamily: "Inter" }}
                onClick={() => refetchRegistries()}
                data-testid={"linked-registry--check-status"}
              >
                {t("CHECK STATUS")}
              </Button>
            )}
            {registry.status === RegistryLinkStatus.RegistrationFailed && (
              <Button
                variant="text"
                sx={{ color: "primary", fontSize: 0, letterSpacing: 1, fontFamily: "Inter" }}
                // Allow selection if the linked registry is in a retry state
                // otherwise, disable already selected registries.
                onClick={() => showRegistryModal({ isRetryState: true })}
                data-testid={"linked-registry--retry"}
              >
                {t("RETRY")}
              </Button>
            )}
          </Flex>
        )}
      </>
    </SquareBlock>
  );
};
