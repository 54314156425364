export const validateRegistryId = (registryCode: string | undefined, registryId: string): boolean => {
  switch (registryCode) {
    case "MRETS":
      return /^MM\d+$/.test(registryId);
    case "ERCOT":
      // allow number,letters and space
      return /^[A-Za-z0-9][A-Za-z0-9 ]*$/.test(registryId);
    case "OTHERS":
      /** Preserved for Proper UUID Regexp */
      return /^[0-9A-Za-z]{8}-[0-9A-Za-z]{4}-[0-9A-Za-z]{4}-[0-9A-Za-z]{4}-[0-9A-Za-z]{12}$/i.test(registryId);
    default:
      return true;
  }
};
