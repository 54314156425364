import { ReactElement } from "react";

import { AccountType } from "@/app/types/generated/graphql";

export type LayoutHeaderProps = {
  header?: ReactElement | null;
  userInfo: {
    email: string;
    name: string;
    accountType?: AccountType | null;
    canViewRegistry?: boolean;
  };
  enableMultipleRegistry: boolean;
};

export const InAppNotification = {
  ShowNotification: false,
};
