import { Box, Divider, Flex, Heading } from "@powerledger/ui-component-lib";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { BeneficialOwners } from "@/app/components/beneficial-owners";
import { CompanyInformation } from "@/app/components/company-information";
import { NominatedController } from "@/app/components/nominated-controller";
import { getSupportEmail } from "@/app/lib/get-support-email";
import { Company, useAccountQuery } from "@/app/types/generated/graphql";

export const CompanyDetailsTab = () => {
  const { t } = useTranslation();

  const supportEmail = getSupportEmail();
  const { data: accountData, loading: accountLoading } = useAccountQuery();
  const company = useMemo(() => accountData?.account?.company, [accountData]);

  return (
    <>
      <Heading as="h2">{t("Primary Details")}</Heading>

      <Flex sx={{ mt: 4 }}>
        <Box sx={{ p: 2, fontSize: 0, textAlign: "center", color: "textDarker" }}>
          {t(
            "If these details have changed since you last submitted, please reach out to customer support at {{supportEmail}} to update them",
            { supportEmail },
          )}
        </Box>
      </Flex>

      <Box sx={{ py: 5 }}>
        <CompanyInformation company={company as Company} loading={accountLoading} />
      </Box>
      <Divider sx={{ color: "textDarkest" }} />
      <Box sx={{ py: 5 }}>
        <NominatedController company={company as Company} loading={accountLoading} />
      </Box>
      <Divider sx={{ color: "textDarkest" }} />
      <Box sx={{ py: 5 }}>
        <BeneficialOwners noEdit company={company as Company} loading={accountLoading} />
      </Box>
    </>
  );
};
