import { Box, Button, Flex, Text } from "@powerledger/ui-component-lib";
import currency from "currency.js";
import { format } from "date-fns";
import { TFunction } from "i18next";
import { Column, Row } from "react-table";
import ReactTooltip from "react-tooltip";

import { ForwardTradeAttributesTooltip } from "@/app/components/forward-trade-attributes-tooltip";
import { TableValueWrapper } from "@/app/components/table-value-wrapper";
import { HeaderWithToolTip } from "@/app/components/tooltips";
import { AppDateFormats } from "@/app/lib/format-date";
import { AccountType, RecAttributes, TradePosition } from "@/app/types/generated/graphql";

import { ForwardMarketTradeContract } from "../components";

export const getForwardTradeMarketColumns = (
  t: TFunction,
  accountData: { accountType?: AccountType | null; id?: string },
  onAcceptDeal: (tradeContract: ForwardMarketTradeContract) => void,
) => {
  const tableColumns: Column<ForwardMarketTradeContract>[] = [
    {
      id: "createdDate",
      Header: <HeaderWithToolTip titleText="OLD" tooltipText="Order Lodgement Date" />,
      accessor: "createdDate",
      Cell: ({ value }) => {
        const displayValue = format(new Date(value), AppDateFormats.AbbreviatedMonthFormat);
        return (
          <TableValueWrapper value={displayValue} csv title="Order Lodgement Date" containerSx={{ minWidth: 80 }} />
        );
      },
    },
    {
      Header: <HeaderWithToolTip titleText="TSD" tooltipText="Trade Settlement Date" />,
      accessor: "tradeSettlementDate",
      Cell: ({ value }) => {
        const displayValue = format(new Date(value), AppDateFormats.AbbreviatedMonthFormat);
        return (
          <TableValueWrapper value={displayValue} csv title="Trade Settlement Date" containerSx={{ minWidth: 80 }} />
        );
      },
    },
    {
      Header: () => t("Participant"),
      accessor: "account",
      disableSortBy: true,
      Cell: ({ value }) => (
        <Flex
          sx={{
            flexBasis: "100px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Text>{value.company?.businessName ?? "-"}</Text>
        </Flex>
      ),
    },
    {
      Header: (
        <Box
          sx={{
            textAlign: "right",
            width: "100px",
          }}
        >
          {t("Price USD")}
        </Box>
      ),
      width: "100px",
      accessor: "unitPrice",
      Cell: ({ value }) => {
        return (
          <Box
            sx={{
              textAlign: "right",
              width: "120px",
              color: "textDark",
              px: 2,
            }}
          >
            {currency(value || 0, { separator: ",", fromCents: true }).format()}
          </Box>
        );
      },
    },
    {
      Header: () => t("Quantity"),
      accessor: "volume",
      Cell: ({ value }) => <TableValueWrapper value={value} />,
    },
    {
      Header: t("Product Attributes"),
      accessor: "attributes",
      Cell: ({ row }) => {
        const { formattedAttributes, attributes } = row.original;

        // Filter out formatted attributes with content that include 'Any' or '-' i.e. that are null in original attributes to display in the table row
        const displayAttributes = Object.fromEntries(
          Object.entries(formattedAttributes).filter(([key, _]) => !!attributes[key as keyof RecAttributes]),
        );

        const { vintages, ...rest } = displayAttributes;
        delete rest.__typename;

        const tooltipRowData = {
          attributes: {
            commencementDate: attributes.commencementDate,
            fuelSources: attributes.fuelSources,
          },
          vintages: formattedAttributes.vintages,
          certificationsAndEligibilities: formattedAttributes.certificationsAndEligibilities,
          fuelSources: formattedAttributes.fuelSources,
        };

        const tooltipContent = (dataTip: string) => {
          return dataTip === "rec-tooltip" ? (
            <Box sx={{ color: "text", whiteSpace: "pre-line", backgroundColor: "shadow", p: 3, borderRadius: 8 }}>
              <ForwardTradeAttributesTooltip tooltipData={tooltipRowData} />
            </Box>
          ) : null;
        };

        return (
          <>
            <ReactTooltip
              id={row.original.id}
              backgroundColor="shadow"
              arrowColor="#D0D5DD"
              getContent={(dataTip) => tooltipContent(dataTip)}
              padding="0"
            />
            <Box sx={{ width: "200px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              <Text data-tip="rec-tooltip" data-for={row.original.id}>
                {[vintages, ...Object.values(rest)].flat().filter(Boolean).join(", ")}
              </Text>
            </Box>
          </>
        );
      },
      disableSortBy: true,
    },
    {
      Header: () => t("Order Type"),
      accessor: "position",
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          <Box
            sx={{
              variant: value === TradePosition.Ask ? "chips.error" : "chips.info",
              width: "40px",
            }}
          >
            {value}
          </Box>
        );
      },
    },
    {
      Header: t("Action"),
      id: "action",
      Cell: ({ row }: { row: Row<ForwardMarketTradeContract> }) => {
        const { position, account } = row.original;
        const isTradePositionBid = position === TradePosition.Bid;
        const isNonRegistryUser = accountData.accountType === AccountType.NonRegistry;
        const isOwnTrade = account.id === accountData.id;
        const cannotAcceptDeal = isOwnTrade || (isTradePositionBid && isNonRegistryUser);

        return cannotAcceptDeal ? (
          <></>
        ) : (
          <Button variant="pill.compact" sx={{ py: 1, minWidth: "110px" }} onClick={() => onAcceptDeal(row.original)}>
            {t("Accept Order")}
          </Button>
        );
      },
    },
  ];
  return tableColumns;
};
