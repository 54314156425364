import { useTablePaginationManager } from "@powerledger/ui-component-lib";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { notifications } from "@/app/container/notifications";
import { useRecOptions } from "@/app/hooks/use-rec-options";
import { formatAttributes, ReturnTypeFormatAttributes } from "@/app/lib/format-attributes";
import { canShowViewAssets } from "@/app/pages/orders/forward-trade-history/helpers";
import { RecHoldingsTableData } from "@/app/pages/recs/rec-holdings/rec-holdings.types";
import {
  AssetType,
  RecAssetAttributes,
  RegistryServiceSortOrderInput,
  TradeAttributes,
  TradeContractAssetStatus,
  TradeContractStatus,
  useAssignAssetsMutation,
  usePaginatedHoldingsQuery,
  useTradeContractAssetsByTradeContractIdQuery,
} from "@/app/types/generated/graphql";

import {
  AssignAssetsFormValues,
  ForwardTradeRecsAssignModalProps,
  TradeContractAssetsTableData,
} from "./forward-trade-recs-assign-modal.types";

export const useForwardTradeRecsAssignModal = ({
  visible,
  closeModal,
  tradeContractNumber,
  tradeContractId,
  askTradeVolume,
  registryCode,
  tradeAttributes,
  status,
}: ForwardTradeRecsAssignModalProps) => {
  const { t } = useTranslation();

  const { recOptions, loading: recOptionsLoading } = useRecOptions();

  const { pageInfo, offset, fetchData, sort, setTotalItems } = useTablePaginationManager<
    RecHoldingsTableData,
    RegistryServiceSortOrderInput[]
  >({});

  const {
    data: recsHoldingData,
    loading: recsHoldingLoading,
    refetch: recsHoldingRefetch,
  } = usePaginatedHoldingsQuery({
    fetchPolicy: "cache-and-network",
    skip: status !== TradeContractStatus.Contracted,
    variables: {
      input: {
        offSetPaginationInfo: {
          offset,
          limit: pageInfo.pageSize,
        },
        sortOrderInputs: sort,
        where: {
          recAttributes: {
            eligibilities: tradeAttributes.eligibilities,
            certifications: tradeAttributes.certifications,
            locations: tradeAttributes.locations,
            fuelSources: tradeAttributes.fuelSources,
            vintages: tradeAttributes.vintages,
            projects: tradeAttributes.projects,
            commencementDate: tradeAttributes.commencementDate,
          },
        },
      },
    },
    onCompleted(data) {
      setTotalItems(data?.paginatedHoldings?.offsetInfo?.total ?? 0);
    },
  });

  const recsHoldingTableData = useMemo(() => {
    const formmatedRecHoldingData: RecHoldingsTableData[] = [];
    if (!recsHoldingData?.paginatedHoldings.holdings) return formmatedRecHoldingData;
    for (const recHolding of recsHoldingData.paginatedHoldings.holdings) {
      const attributes = recHolding.attributes as RecAssetAttributes;
      const formattedAttributes = recOptions
        ? formatAttributes({
            attributes,
            options: recOptions,
          })
        : ({} as ReturnTypeFormatAttributes);
      formmatedRecHoldingData.push({
        ...recHolding,
        attributes,
        ...formattedAttributes,
      });
    }
    return formmatedRecHoldingData;
  }, [recsHoldingData?.paginatedHoldings?.holdings, recOptions]);

  const reloadRecsHolding = useCallback(() => {
    recsHoldingRefetch();
  }, [recsHoldingRefetch]);

  const {
    data: contractAssetsData,
    loading: contractAssetsLoading,
    refetch: contractAssetsRefetch,
  } = useTradeContractAssetsByTradeContractIdQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      tradeContractId,
    },
  });

  const [assignAssetsToTradeContract] = useAssignAssetsMutation();

  const contractAssetsTableData = useMemo(() => {
    const tradeContractAssetsTableData: TradeContractAssetsTableData[] = [];

    if (!contractAssetsData?.tradeContractAssetsByTradeContractId) return tradeContractAssetsTableData;
    for (const tradeContractAsset of contractAssetsData.tradeContractAssetsByTradeContractId) {
      const attributes = tradeContractAsset.attributes as TradeAttributes;
      const formattedAttributes = recOptions
        ? formatAttributes({
            attributes,
            options: recOptions,
          })
        : ({} as ReturnTypeFormatAttributes);
      tradeContractAssetsTableData.push({
        ...formattedAttributes,
        ...tradeContractAsset,
        attributes,
      });
    }
    return tradeContractAssetsTableData;
  }, [recOptions, contractAssetsData?.tradeContractAssetsByTradeContractId]);

  const reloadTradeContractAssest = useCallback(() => {
    contractAssetsRefetch();
  }, [contractAssetsRefetch]);

  const formikProps = useMemo(() => {
    const assetsToAssign =
      recsHoldingTableData?.map(({ attributes, availableBalance }) => {
        const {
          certifications = [],
          commencementDate = null,
          eligibilities = [],
          fuelSources = [],
          location = null,
          project = null,
          vintage,
          vintageHalf,
        } = attributes;

        return {
          recAttributes: {
            certifications,
            commencementDate,
            eligibilities,
            fuelSources,
            locations: location ? [location] : undefined,
            projects: project ? [project] : undefined,
            vintages: [`${vintage}-${vintageHalf}`],
          },
          volume: 0,
          // Adding property to track the maximum assignable RECs
          availableVolume: availableBalance,
        };
      }) ?? [];

    const initialValues: AssignAssetsFormValues = {
      assetType: AssetType.Rec,
      tradeContractId: tradeContractId,
      registryCode: registryCode,
      assetsToAssign,
    };

    return { initialValues };
  }, [recsHoldingTableData, tradeContractId, registryCode]);

  const onAssignRecs = useCallback(
    async (values: AssignAssetsFormValues) => {
      try {
        const processedValues = {
          ...values,
          assetsToAssign: values.assetsToAssign
            .filter(({ volume }) => Number(volume) > 0)
            .map(({ availableVolume, volume, ...rest }) => ({
              ...rest,
              volume: Number(volume),
            })),
        };

        const { data } = await assignAssetsToTradeContract({
          variables: {
            input: {
              ...processedValues,
            },
          },
        });
        reloadTradeContractAssest();
        reloadRecsHolding();
        if (data?.assignAssetsToTradeContract) {
          notifications.info({ description: t("RECs are currently being assigned.") });
        }
      } catch (error) {
        error instanceof Error &&
          notifications.error({
            description: t(error?.message),
          });
      }
    },
    [t, assignAssetsToTradeContract, reloadRecsHolding, reloadTradeContractAssest],
  );

  // While assigning RECs if locking fails i.e "LockFailed" status,
  // filter them before adding to the total assigned RECs.
  const totalAssignedRecs = useMemo(
    () =>
      contractAssetsTableData
        .filter((item) => item.status !== TradeContractAssetStatus.LockFailed)
        .reduce((total, item) => total + (item.volume || 0), 0),
    [contractAssetsTableData],
  );

  //original ask volume subtract with the total assets assigned volume
  const currentRequiredVolume = useMemo(() => {
    return askTradeVolume - totalAssignedRecs;
  }, [askTradeVolume, totalAssignedRecs]);

  //Only Displayed in action "Assign RECs"
  const showAssignRECsTable = useMemo(
    () => status === TradeContractStatus.Contracted && currentRequiredVolume > 0,
    [status, currentRequiredVolume],
  );

  // Displayed in both action "Assign RECs" (when RECs are being assigned)
  // and "View RECs" (for already assigned RECs).
  const showViewRECsTable = useMemo(
    () => canShowViewAssets(status) || totalAssignedRecs > 0,
    [status, totalAssignedRecs],
  );

  return {
    visible,
    closeModal,
    contractAssetsTableData,
    recsHoldingTableData,
    tradeContractNumber,
    recOptions,
    contractAssetsLoading: contractAssetsLoading || recOptionsLoading,
    recsHoldingLoading: recOptionsLoading || recsHoldingLoading,
    reloadTradeContractAssest,
    formikProps,
    onAssignRecs,
    pageInfo,
    fetchData,
    reloadRecsHolding,
    currentRequiredVolume,
    showAssignRECsTable,
    showViewRECsTable,
    totalAssignedRecs,
  };
};
