import currency from "currency.js";

import { createTransactionSummaryDataForCSV, downloadCSV } from "@/app/components/csv-components";
import {
  TransactionSummaryData,
  TransactionSummaryTransactionContractProductAttributes,
} from "@/app/components/pdf-components/documents/transaction-summary/transaction-summary.types";
import { downloadReactPDFDocument } from "@/app/components/pdf-components/react-pdf-helpers";
import { formatAttributes } from "@/app/lib/format-attributes";
import { RECOrderAttributeOptions } from "@/app/lib/format-rec-options";
import { getTotalValue, reverseFeePercentageCalculation } from "@/app/lib/order-helpers";
import {
  OrderType,
  TradeAttributes,
  TradeContractStatus,
  TradeContractTransactionSummaryLazyQueryHookResult,
  TradeContractTransactionSummaryQuery,
  TradePosition,
} from "@/app/types/generated/graphql";

import { SupportedDocumentType } from "../components/download-report-buttons";

export const canShowViewAssets = (status: TradeContractStatus) => {
  return [
    TradeContractStatus.AssetsLoaded,
    TradeContractStatus.PendingComplete,
    TradeContractStatus.Failed,
    TradeContractStatus.Completed,
  ].includes(status);
};

export const tradeContractTransactionSummaryDataMapper = (
  data: TradeContractTransactionSummaryQuery,
  recOptions: RECOrderAttributeOptions | undefined,
): TransactionSummaryData => {
  const {
    tradeContractTransactionSummary: { tradeContract, tradeTransactions },
  } = data;

  const formattedAttributes = formatAttributes({
    attributes: tradeContract.attributes as TradeAttributes,
    position: TradePosition.Bid,
    options: recOptions,
  });

  const transactions = tradeTransactions.map((transaction) => {
    const total = currency(transaction.unitPrice).multiply(transaction.volume).value;
    const feePercentage = reverseFeePercentageCalculation(transaction.transactionFee, total);

    const productAttributes = transaction.transactionDetails?.map(
      (detail): TransactionSummaryTransactionContractProductAttributes => {
        const { volume, trackingSystem, attributes, commodityGenerationSummary, generator } = detail;
        const vintages = commodityGenerationSummary?.map(({ date }) => {
          const [year, month] = date.split("-");
          return `${month}-${year}`;
        });

        const formattedAttributes = formatAttributes({
          attributes: attributes as TradeAttributes,
          position: TradePosition.Bid,
          options: recOptions,
        });

        return {
          volume,
          trackingSystem,
          eligibilities: formattedAttributes.eligibilities,
          fuelSources: formattedAttributes.fuelSources,
          vintages,
          projects: generator?.name ? [generator.name] : [],
          locations: formattedAttributes.locations,
          certificationScheme: {
            certifications: formattedAttributes.certifications,
            crsEffectiveDate: generator?.crsEffectiveDate ?? "",
            crsExpiryDate: generator?.crsExpiryDate ?? "",
            commencementDate: generator?.commencementDate ?? "",
          },
        };
      },
    );

    return {
      contractId: transaction.transactionNumber ?? "",
      marketplace: "TraceX USA",
      participantName: tradeContract.account.company?.businessName ?? "",
      counterPartyName: tradeContract.counterAccount?.company?.businessName ?? "",
      position: transaction.position,
      matchedDate: transaction.matchedDate,
      completedDate: transaction.completedDate,
      price: transaction.unitPrice,
      volume: transaction.volume,
      totalContractValue: getTotalValue(
        transaction.unitPrice,
        transaction.volume,
        tradeContract.position,
        feePercentage / 100,
      ).value,
      transactionFeeAmount: transaction.transactionFee,
      transactionFeePercentage: feePercentage,
      productAttributes,
    };
  });

  const totalOrderValue =
    tradeContract.status === TradeContractStatus.Withdrawn && transactions.length === 0
      ? currency(tradeContract.unitPrice).multiply(tradeContract.volume).value
      : transactions.reduce((acc, transaction) => currency(acc).add(transaction.totalContractValue).value, 0);

  return {
    order: {
      orderNumber: tradeContract.tradeContractNumber,
      orderDate: tradeContract.createdDate,
      marketplace: "TraceX USA",
      participantName: tradeContract.account.company?.businessName ?? "",
      counterPartyName: tradeContract.counterAccount?.company?.businessName ?? "",
      position: tradeContract.position,
      type: OrderType.Forward,
      volume: tradeContract.volume,
      price: +tradeContract.unitPrice,
      assetType: tradeContract.assetType,
      totalOrderValue,
      currencyCode: tradeContract.currencyCode,
      attributes: {
        eligibilities: formattedAttributes.eligibilities,
        fuelSources: formattedAttributes.fuelSources,
        vintages: formattedAttributes.vintages,
        locations: formattedAttributes.locations,
        certifications: formattedAttributes.certifications,
      },
      status: tradeContract.status,
    },
    transactions,
  };
};

export const downloadTransactionSummaryReport = async (
  type: SupportedDocumentType,
  orderId: string,
  orderNumber: string,
  recOptions: RECOrderAttributeOptions | undefined,
  transactionSummaryQuery: TradeContractTransactionSummaryLazyQueryHookResult[0],
  pdfRenderer: (data: TransactionSummaryData) => React.ReactElement,
) => {
  const { data, error } = await transactionSummaryQuery({
    variables: { tradeContractId: orderId },
  });

  if (data) {
    const transactionSummaryData = tradeContractTransactionSummaryDataMapper(data, recOptions);
    if (type === "pdf") {
      return downloadReactPDFDocument(
        `forward_trade_transaction_summary_${orderNumber}.pdf`,
        pdfRenderer(transactionSummaryData),
      );
    }
    if (type === "csv") {
      return downloadCSV(
        `forward_trade_transaction_summary_${orderNumber}.csv`,
        createTransactionSummaryDataForCSV({
          transactionSummary: transactionSummaryData,
          tradeType: "Forward",
        }),
      );
    }
  } else {
    throw error;
  }
};
