import { Box, Button, Flex, IconLoading, IconSave, Text, ThemeToggle } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { TraceXLogo } from "@/app/components/tracex-logo";
import { APOLLO_GRAPH_VARIANT } from "@/app/lib/env-helpers";

export const OnboardingHeader = ({ saveAndExit = async () => {}, isSavingBeforeExit = false }) => {
  const { t } = useTranslation();

  return (
    <Flex sx={{ alignItems: "center", justifyContent: "space-between", pr: 3, pt: 3 }}>
      <Box sx={{ pl: 4, pt: 3 }}>
        <Link to="/">
          <Box>
            <TraceXLogo />
          </Box>
        </Link>
      </Box>
      <Flex
        sx={{
          alignItems: "center",
        }}
      >
        <Button type="button" variant="inverted" onClick={saveAndExit} data-testid={"signup-stepper--save-and-exit"}>
          <Flex sx={{ alignItems: "center", display: "inline-flex" }}>
            <IconSave size={3} noSvgPositioning sx={{ mr: 2 }} color="text" />
            <Text>{t("Save & Exit")}</Text>
          </Flex>
        </Button>
        {isSavingBeforeExit && <IconLoading size="small" sx={{ ml: 2 }} />}
        {APOLLO_GRAPH_VARIANT === "development" && <ThemeToggle />}
      </Flex>
    </Flex>
  );
};
