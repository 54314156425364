import { FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import notifications from "@/app/container/notifications";
import { useAppContext } from "@/app/context";
import { useRecOptions } from "@/app/hooks/use-rec-options";
import { formatAttributes } from "@/app/lib/format-attributes";
import {
  PaginatedHoldingsDocument,
  RecAssetAttributes,
  RegistryCode,
  RetirementCategory,
  useAccountQuery,
  usePaginatedHoldingsQuery,
  useRetireCommoditiesMutation,
} from "@/app/types/generated/graphql";

import { RecHoldingsTableData } from "../rec-holdings";
import { RetirementFormValues, RetirementLifeCycle, RetirementType, UseRetireFn } from "./retire.types";

const currentYear = new Date().getFullYear();

export const getInitialRetirementValues = (recData: RecHoldingsTableData[], args = {}) => ({
  category: RetirementCategory.Voluntary,
  type: RetirementType.BENEFICIAL_OWNERSHIP,
  compliancePeriod: null,
  reason: null,
  notes: null,
  values: recData.map((data) => ({
    ...data,
    actionVolume: 0,
  })),
  ...args,
});

export const retirementOptions = Object.values(RetirementType).map((opt) => ({ label: opt, value: opt }));
export const retirementChoices = [
  {
    name: RetirementType.BENEFICIAL_OWNERSHIP,
    key: "type",
    choices: [
      {
        name: "Reason",
        key: "reason",
        options: [
          {
            label: "For Environmental Benefit",
            value: "For Environmental Benefit",
          },
        ],
      },
    ],
  },
  {
    name: RetirementType.GREEN_E_CERTIFIED_VOLUNTARY_MARKET,
    key: "type",
    choices: [
      {
        name: "Reason",
        key: "reason",
        options: [
          {
            label: "Green Electricity Program",
            value: "Green Electricity Program",
          },
          {
            label: "REC Only",
            value: "REC Only",
          },
          {
            label: "Utility Green Pricing Program",
            value: "Utility Green Pricing Program",
          },
        ],
      },
      {
        name: "Select a Year",
        key: "compliancePeriod",
        options: Array.from({ length: 10 }, (_, i) => {
          const year = `${currentYear - 7 + i}`;
          return { label: year, value: year };
        }).sort((x, y) => +y.value - +x.value),
      },
    ],
  },
];

const performRetirement = async (
  retireCommodities: ReturnType<typeof useRetireCommoditiesMutation>["0"],
  values: RetirementFormValues,
  registryCode?: RegistryCode,
) => {
  await retireCommodities({
    variables: {
      retireCommoditiesInput: {
        commodityDetails: values.values.map((val) => ({
          holdingId: val.id,
          volume: Number(val.actionVolume),
        })),
        retirementCategory: values.category,
        retirementDetails: {
          reason: values.reason,
          compliancePeriod: values.compliancePeriod,
          notes: values.notes, // notePrefix would automatically be appended due to CleaveInput being used with prefix
        },
        retirementType: values.type,
        registryCode: registryCode,
      },
    },
  });
};

export const useRetire: UseRetireFn = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const { userSelections } = useAppContext();

  const [retireCommodities] = useRetireCommoditiesMutation({
    refetchQueries: [PaginatedHoldingsDocument],
  });

  const selectedRecs = useMemo(() => location.state?.selectedRecs ?? [], [location.state]);

  const [currentStep, setCurrentStep] = useState(RetirementLifeCycle.Form);

  const [savedData, setSavedData] = useState<RetirementFormValues>();

  const { data: accountData } = useAccountQuery();

  const notePrefix = t("Retired for {{businessName}}", {
    businessName: accountData?.account?.company?.businessName,
  });

  const { recOptions, loading: recOptionsLoading } = useRecOptions();

  const { loading: queryLoading, data: holdingsData } = usePaginatedHoldingsQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        offSetPaginationInfo: {
          offset: 0,
          limit: selectedRecs.length,
        },
        where: {
          ids: selectedRecs,
        },
      },
    },
  });

  const tableData: RecHoldingsTableData[] = useMemo(() => {
    return recOptionsLoading || !recOptions
      ? []
      : holdingsData?.paginatedHoldings?.holdings?.map((recHolding) => {
          const attributes = recHolding.attributes as RecAssetAttributes;
          return {
            ...recHolding,
            attributes,
            ...(recHolding?.attributes &&
              formatAttributes({
                attributes,
                options: recOptions,
              })),
          };
        }) || [];
  }, [recOptionsLoading, recOptions, holdingsData?.paginatedHoldings?.holdings]);

  const loading = queryLoading;

  useEffect(() => {
    if (!selectedRecs?.length || (!loading && !holdingsData?.paginatedHoldings?.holdings?.length))
      navigate("/recs/managed-recs");
  }, [selectedRecs, loading, navigate, holdingsData?.paginatedHoldings?.holdings]);

  const onProceed = useCallback(
    async (values: RetirementFormValues, actions?: FormikHelpers<RetirementFormValues>) => {
      if (currentStep === RetirementLifeCycle.Form) {
        setCurrentStep(RetirementLifeCycle.Summary);
        setSavedData({
          ...values,
          notes: values.notes ?? notePrefix,
        });
      } else if (currentStep === RetirementLifeCycle.Summary) {
        try {
          await performRetirement(retireCommodities, values, userSelections?.registry?.registryCode as RegistryCode);
          setCurrentStep(RetirementLifeCycle.Success);
        } catch (e) {
          e instanceof Error &&
            notifications.error({
              description: t(e?.message),
            });
          actions?.setSubmitting(false);
        }
      } else {
        navigate("/recs/retirement-history");
      }
    },
    [currentStep, retireCommodities, navigate, t, notePrefix, userSelections?.registry?.registryCode],
  );

  const onGoBack = useCallback(() => {
    if (currentStep === RetirementLifeCycle.Summary) {
      setCurrentStep(RetirementLifeCycle.Form);
    } else navigate("/recs/managed-recs");
  }, [currentStep, navigate]);

  const initialValues: RetirementFormValues = useMemo(
    () => (savedData?.values?.length ? savedData : getInitialRetirementValues(tableData)),
    [savedData, tableData],
  );

  const formikProps = useMemo(
    () => ({
      initialValues,
    }),
    [initialValues],
  );

  return {
    tableData,
    loading,
    recOptions,
    notePrefix,
    currentStep,
    formikProps,
    onProceed,
    onGoBack,
  };
};
