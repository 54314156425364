import { SearchSVG } from "@powerledger/ui-component-lib";
import { startCase } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Flex } from "theme-ui";

import { Input } from "@/app/components/input";
import { defaultFilter, handleFilterChange, PageHeader } from "@/app/components/page-header";
import { FiltersType, StackedFilterState } from "@/app/components/page-header/filter/filter.types";
import { ExportRequestStatus } from "@/app/types/generated/graphql";

import DateRangeFilter from "../../common/date-range-filter/date-range-filter";
import { ExportHistoryHeaderProps } from "../export-history.types";

export const exportRequestStatusOptions = Object.values(ExportRequestStatus).map((status) => ({
  label: startCase(status.toLowerCase()),
  value: status,
}));

export default function ExportHistoryHeader({ applyFilters, isMenuOpen }: Readonly<ExportHistoryHeaderProps>) {
  const [filterValues, setFilterValues] = useState<StackedFilterState>(defaultFilter);
  const { t } = useTranslation();

  const renderDateRangeFilter = useCallback(
    () => <DateRangeFilter filterValues={filterValues} setFilterValues={setFilterValues} />,
    [filterValues],
  );

  const renderSearchField = useCallback(
    () => (
      <Flex sx={{ position: "relative", alignItems: "center" }}>
        <Input
          name="search export number"
          sx={{
            width: 230,
            color: "textDarker",
            border: "1px solid var(--theme-ui-colors-shadow)",
            bg: "transparent",
            height: 36,
          }}
          prefix={{
            outside: false,
            component: (
              <Flex sx={{ alignItems: "center", svg: { path: { fill: "text" } } }}>
                <SearchSVG width={20} />
              </Flex>
            ),
          }}
          value={filterValues.actionCodes[0]?.value ?? ""}
          onChange={(e) =>
            setFilterValues((prev) => ({
              ...prev,
              actionCodes: e.target.value
                ? [
                    {
                      key: new Date(),
                      value: e.target.value,
                    },
                  ]
                : [],
            }))
          }
          placeholder={t("Enter Export Number")}
        />
      </Flex>
    ),
    [t, filterValues.actionCodes],
  );

  const filters: FiltersType = useMemo(
    () => [
      {
        name: "Export Number",
        keys: ["actionCodes"],
        onChange: () => {},
        options: [],
        disabled: false,
        values: [],
        isDropdown: false,
        isMulti: true,
        render: renderSearchField,
      },
      {
        name: "Requested Date (Range)",
        keys: ["dateRange"],
        onChange: () => {},
        options: [],
        disabled: false,
        values: [],
        isMulti: true,
        render: renderDateRangeFilter,
        isMenuOpen,
        wrapperStyles: {
          menuStyles: {
            height: "320px",
            top: "45px",
          },
        },
      },
      {
        name: "Status",
        keys: ["statuses"],
        options: exportRequestStatusOptions,
        onChange: (options) => {
          if (Array.isArray(options)) {
            handleFilterChange({ statuses: options.map((opt) => opt.value) }, setFilterValues);
          }
        },
        disabled: false,
        values: filterValues.statuses?.map((val) => val.value),
        wrapperStyles: {
          menuStyles: {
            height: "300px",
            top: "45px",
          },
        },
        isMulti: true,
        isMenuOpen,
      },
    ],
    [filterValues, isMenuOpen, renderSearchField, renderDateRangeFilter],
  );

  return (
    <PageHeader
      title={t("Export History")}
      filterProps={{
        applyFilters,
        shouldSelectVintage: false,
        hideStatusFilter: true,
        filters,
        values: filterValues,
        customSetter: setFilterValues,
      }}
    />
  );
}
