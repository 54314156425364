import { endOfDay, format, max, startOfDay } from "date-fns";
import { TFunction } from "i18next";

import { CertificationAndEliglibilityType } from "@/app/lib/format-attributes";
import { AppDateFormats } from "@/app/lib/format-date";
import { FormattedOption } from "@/app/lib/format-rec-options";
import { OrderExpiry, OrderType, RecAttributes } from "@/app/types/generated/graphql";

export const utcEndOfDay = new Date(new Date().setUTCHours(23, 59, 59, 999)).toISOString();

export const orderExpiriesOptions: FormattedOption = [
  { label: "N/A", value: "" },
  { label: `${OrderExpiry.Gtc} (Good Till Cancelled)`, value: OrderExpiry.Gtc },
  {
    label: `${OrderExpiry.Gtd} (Good Till Today)`,
    value: OrderExpiry.Gtd,
  },
  { label: `Custom`, value: OrderExpiry.Custom },
];

export const orderTypeOptions: FormattedOption = [
  { label: `${OrderType.Limit} (Default)`, value: OrderType.Limit },
  {
    label: `${OrderType.Aon} (All or None)`,
    value: OrderType.Aon,
  },
  {
    label: `${OrderType.Fok} (Fill or Kill)`,
    value: OrderType.Fok,
  },
  {
    label: `${OrderType.Ioc} (Immediate or Cancel)`,
    value: OrderType.Ioc,
  },
];

export enum AllowCounterOffer {
  YES = "Yes",
  NO = "No",
}

export const allowCounterOfferOptions = [
  { label: AllowCounterOffer.YES, value: AllowCounterOffer.YES },
  { label: AllowCounterOffer.NO, value: AllowCounterOffer.NO },
];

/**
 *
 * Return minTime based on current Date (if selected date is current Date) or based on selected date at 11:59PM
 * Max time is always end of today / selectedDate
 * @param selectedDate
 * @param options
 * @returns
 */
export const getMinAndMaxTimeForCustomOrderExpiry = (selectedDate?: string | null) => {
  const today = new Date();
  if (selectedDate) {
    const minTime = max([today, startOfDay(new Date(selectedDate))]);
    return [minTime, endOfDay(new Date(selectedDate))];
  }
  return [today, endOfDay(today)];
};

export const isOrderExpiryNotApplicable = (orderType: OrderType) => {
  return [OrderType.Fok, OrderType.Ioc].includes(orderType);
};

export const getDefaultProductAttributeValue = (t: TFunction, values?: string[] | null) => {
  if (!values?.length) {
    return t("Any");
  }

  return values.join(", ");
};

export const getProductAttributesAsDisplayAttributes = (
  t: TFunction,
  tradeContract: {
    attributes: RecAttributes;
    formattedAttributes: RecAttributes & { certificationsAndEligibilities: CertificationAndEliglibilityType[] };
  },
) => {
  return [
    {
      label: "Product",
      value: "M-RETS REC", // to be made dynamic after ERCOT integration
    },
    {
      label: "Vintage",
      value: getDefaultProductAttributeValue(t, tradeContract.formattedAttributes.vintages),
    },
    {
      label: "Eligibility",
      value: getDefaultProductAttributeValue(
        t,
        tradeContract.formattedAttributes.certificationsAndEligibilities.flatMap(({ value }) => value),
      ),
    },
    {
      label: "Location",
      value: getDefaultProductAttributeValue(t, tradeContract.formattedAttributes.locations),
    },
    {
      label: "Fuel Type",
      value: getDefaultProductAttributeValue(t, tradeContract.formattedAttributes.fuelSources),
    },
    {
      label: "Commencement of Operations Date (COD)",
      value: tradeContract.attributes.commencementDate
        ? t(`Not older than {{cod}}`, {
            cod: format(new Date(tradeContract.attributes.commencementDate), AppDateFormats.AbbreviatedMonthFormat),
          })
        : t("Any"),
    },
  ];
};
