import { Box } from "@powerledger/ui-component-lib";
import { lazy, useMemo } from "react";

import { LoadingOverlayIndicator } from "@/app/components/loading-overlay-indicator";
import { SignupStepperStages } from "@/app/lib/account-service-helpers";

const AddFundingSourceStage = lazy(
  () => import("../payment-services-step/add-funding-source-stage/add-funding-source-stage"),
);
const SelectProviderStage = lazy(() => import("../payment-services-step/select-provider-stage/select-provider-stage"));

const RecRegistryStep = lazy(() => import("../rec-registry-step/rec-registry-step"));

import AccountTypeSelection from "../account-type-selection/account-type-selection";
import ApplicantBeneficialOwnersStep from "../applicant-beneficial-owner-step/applicant-beneficial-owner-step";
import ApplicantControllerStep from "../applicant-controller-step/applicant-controller-step";
import ApplicantDetailsStep from "../applicant-details-step/applicant-details-step";
import { RegionSelectionStep } from "../region-selection-step/region-selection-step";
import RequiredDocumentationStep from "../required-documentation-step/required-documentation-step";
import { ErrorDisplayPopper } from "./errors-display-popper/error-display-popper";
import { OnboardingProvider } from "./onboarding-provider";
import { useOnboarding } from "./use-onboarding";

const ConfirmDetailsStep = lazy(() => import("../confirm-details-step/confirm-details-step"));

export const Onboarding = () => {
  const stepComponents = useMemo(
    () => ({
      [SignupStepperStages.AccountTypeSelection]: <AccountTypeSelection />,
      [SignupStepperStages.MarketSelection]: <RegionSelectionStep />,
      [SignupStepperStages.ApplicantDetails]: <ApplicantDetailsStep />,
      [SignupStepperStages.ApplicantControllerDetails]: <ApplicantControllerStep />,
      [SignupStepperStages.ApplicantBeneficialOwners]: <ApplicantBeneficialOwnersStep />,
      [SignupStepperStages.RequiredDocumentation]: <RequiredDocumentationStep />,
      [SignupStepperStages.PaymentServiceMethodSelection]: <SelectProviderStage />,
      [SignupStepperStages.LinkBankAccount]: <AddFundingSourceStage />,
      [SignupStepperStages.LinkRegistryAccount]: <RecRegistryStep />,
      [SignupStepperStages.FinalVerification]: <ConfirmDetailsStep />,
    }),
    [],
  );
  const {
    providerValues,
    loading,
    saveAndExit,
    steps,
    initialFormValues,
    currentValidationSchema,
    getCurrentTouched,
    formRef,
  } = useOnboarding({ stepComponents });

  if (loading) {
    return <LoadingOverlayIndicator />;
  }
  return (
    <>
      <ErrorDisplayPopper />
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            opacity: 0.2,
            left: 0,
            right: 0,
            top: 0,
            zIndex: -1,
            bottom: 0,
            background: "url('/assets/loginBackground.svg')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
            backgroundSize: ["auto", null, null, null, null, "cover"],
          }}
        ></Box>
        <OnboardingProvider
          providerValues={providerValues}
          saveAndExit={saveAndExit}
          steps={steps}
          initialFormValues={initialFormValues}
          currentValidationSchema={currentValidationSchema}
          getCurrentTouched={getCurrentTouched}
          formRef={formRef}
        />
      </Box>
    </>
  );
};

export default Onboarding;
