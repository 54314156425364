import { Flex, Text, ThemeUIStyleObject } from "@powerledger/ui-component-lib";
import { Trans } from "react-i18next";
export const AccountSelectionDisclaimer = ({ sx }: { sx?: ThemeUIStyleObject }) => (
  <Flex
    sx={{
      flexDirection: "column",
      lineHeight: "1.6",
      gap: 1,
      fontFamily: "MintGroteskV08",
      ...sx,
    }}
  >
    <Trans>
      <Text>Please select the account type:</Text>
      <ol>
        <li style={{ marginBottom: 8 }}>
          Select{" "}
          <Text as="span" sx={{ fontWeight: "bold" }}>
            Non-Registry
          </Text>
          , if the Applicant entity does{" "}
          <Text as="span" sx={{ fontWeight: "bold" }}>
            NOT
          </Text>{" "}
          have an active Registry Account for the types of Product that it intends to Trade. This account type allows a
          Participant to buy Products on the Marketplace without having their own Registry Account. With this type of
          account, the Participant will be able to buy Products for the purposes of retirement only. The purchased
          Products will be held by the Product Holder subject to the Product Holding Terms. These Products will{" "}
          <Text as="span" sx={{ fontWeight: "bold" }}>
            NOT
          </Text>{" "}
          be able to be resold on the Marketplace.
        </li>
        <li>
          Select{" "}
          <Text as="span" sx={{ fontWeight: "bold" }}>
            Registry
          </Text>
          , if the Applicant entity has an active Registry Account for the types of Product that it intends to Trade.
          You will be able to link your Registry Account later in the onboarding process.
        </li>
      </ol>
    </Trans>
  </Flex>
);
