import { Text, View } from "@react-pdf/renderer";
import React from "react";

import { styles } from "../../pdf-styles";

const LeftValueDisplay = ({ value, header }: { value?: string | React.ReactNode; header?: boolean }) => {
  if (!value) return <View></View>;
  return typeof value === "string" ? (
    <Text style={{ ...(header ? {} : styles.tableH2), ...styles.tableCell }}>{value}</Text>
  ) : (
    <View style={{ ...(header ? {} : styles.tableH2), ...styles.tableCell }}>{value}</View>
  );
};

export const PairTableRow = ({
  header = false,
  leftValue,
  rightValue,
}: {
  header?: boolean;
  leftValue?: string | React.ReactNode;
  rightValue?: any;
}) => {
  return (
    <View style={{ ...(header ? styles.tableHeader : styles.tableRow) }}>
      <LeftValueDisplay value={leftValue} header={header} />
      <Text style={{ ...styles.tableCell }}>{rightValue || ""}</Text>
    </View>
  );
};
