import { Box, Button, ColorModes, Flex, IconLoading, Image, Text, useColorMode } from "@powerledger/ui-component-lib";
import { Field, Formik } from "formik";
import { startCase } from "lodash";
import { ChangeEvent, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { linkRegistrySchema, multipleLinkRegistrySchema } from "@/app/lib/validation";
import { AccountSelectionDisclaimer } from "@/app/pages/public/signup/account-type-selection/account-type-disclaimer-view";
import {
  AccountTypeSelectCard,
  getIcon,
} from "@/app/pages/public/signup/account-type-selection/account-type-selection-view";
import { NonRegistryTypeCheckBox } from "@/app/pages/public/signup/account-type-selection/non-registry-type-checkbox";
import { RegistryFormViewProps } from "@/app/pages/settings/registry-tab/linked-registries/linked-registries.types";
import { AccountType, RegistryCode } from "@/app/types/generated/graphql";
import ErcotLogo from "@/assets/images/ercot-logo.png";
import { ReactComponent as IrecLogo } from "@/assets/images/irecLogo.svg";
import { ReactComponent as MretsLogo } from "@/assets/images/mretsLogo.svg";

import { AccountNumberInput } from "../../account-number-input";

const MRETSLogo = () => {
  const [colorMode] = useColorMode();
  return (
    <Box
      sx={{
        svg: {
          image: {
            filter: colorMode === ColorModes.Dark ? "brightness(1)" : "brightness(0)",
          },
        },
      }}
    >
      <MretsLogo />
    </Box>
  );
};

export const registryLogoMap: Record<string, any> = {
  ERCOT: (
    <Box
      sx={{
        width: "100%",
        height: "75px",
      }}
    >
      <Image
        src={ErcotLogo}
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
        }}
      />
    </Box>
  ),
  MRETS: (
    <Box
      sx={{
        width: "100%",
        height: "75px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        svg: {
          width: "100%",
          height: "35px",
          image: {
            filter: "brightness(0)",
          },
        },
      }}
    >
      <MRETSLogo />
    </Box>
  ),
  IREC: <IrecLogo />,
};

export const RegistryFormView = ({
  showLegalDocumentModal,
  setShowLegalDocumentModal,
  file,
  registryOptions,
  linkedRegistriesData,
  loading,
  loadingTranslatedFile,
  guidanceText,
  validateAccountNumber,
  accountData,
  linkRegistry,
  enableMultipleRegistry,
  singlePage = false,
  closeModal,
  setSelectedRegistry,
  selectedRegistry,
  showModal = true,
  isRetryState = false,
}: RegistryFormViewProps) => {
  const { t } = useTranslation();
  const finishLinking = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const renderRegistryForm = useCallback(
    ({ handleSubmit, isSubmitting, setFieldValue, setStatus, isValid, status, values }) => {
      return (
        <>
          {(!selectedRegistry || singlePage) && (
            <>
              <Flex sx={{ justifyContent: "flex-start", pb: 3, gap: 3 }} data-test-id="registry-form-select">
                {loading ? (
                  <IconLoading />
                ) : (
                  registryOptions?.map((ro) => {
                    const linkedRegistry = linkedRegistriesData?.account?.linkedRegistries?.find(
                      (registry) => registry.registryId === ro.id,
                    );
                    // Allow selection if the linked registry is in a retry state
                    // otherwise, disable already selected registries.
                    const isDisabled = isRetryState ? false : !!linkedRegistry;
                    return (
                      <Button
                        variant="outlined"
                        key={ro?.id}
                        data-test-id={ro.code.toLowerCase()}
                        sx={{
                          borderRadius: 4,
                          border:
                            selectedRegistry === ro
                              ? "2px solid var(--theme-ui-colors-primary-500)"
                              : "1px solid var(--theme-ui-colors-textDark)",
                          width: 150,
                          height: 150,
                          backgroundColor: selectedRegistry === ro ? "primary.25" : "pageBackground",
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: 0,
                          cursor: isDisabled ? "not-allowed" : "pointer",
                          opacity: isDisabled && 0.5,
                        }}
                        onClick={() => {
                          setSelectedRegistry?.(ro);
                          setFieldValue("registryId", ro?.id);
                          setFieldValue("accountNumber", "");
                          setStatus(undefined);
                        }}
                        disabled={isDisabled}
                      >
                        <Flex sx={{ width: "100%", px: 3, alignItems: "center" }}>
                          {registryLogoMap[ro?.code || ""]}
                        </Flex>
                      </Button>
                    );
                  })
                )}
              </Flex>

              {!showModal && !selectedRegistry && (
                <Flex sx={{ justifyContent: "flex-start", alignItems: "center", mt: 4 }}>
                  <Button variant="inverted" sx={{ mr: 2 }} onClick={closeModal} type="button">
                    {t("Cancel")}
                  </Button>
                </Flex>
              )}
            </>
          )}
          {enableMultipleRegistry && selectedRegistry && (
            <>
              <AccountSelectionDisclaimer
                sx={{
                  fontSize: 14,
                }}
              />
              <Flex
                sx={{
                  gap: 2,
                }}
                data-test-id="registry-account-type-select"
              >
                <AccountTypeSelectCard
                  disabled={false}
                  text={t("{{type}}", {
                    type: startCase(AccountType.NonRegistry),
                  })}
                  getIcon={(selected, disabled) => getIcon(selected, disabled, AccountType.NonRegistry)}
                  selected={values.type === AccountType.NonRegistry}
                  onClick={() => {
                    setFieldValue("type", AccountType.NonRegistry);
                    setFieldValue("accountNumber", "");
                    setStatus(undefined);
                  }}
                  testID={`${selectedRegistry.code.toLowerCase()}-non-registry`}
                />
                <AccountTypeSelectCard
                  disabled={false}
                  text={t("{{type}}", {
                    type: startCase(AccountType.Registry),
                  })}
                  getIcon={(selected, disabled) => getIcon(selected, disabled, AccountType.Registry)}
                  selected={values.type === AccountType.Registry}
                  onClick={() => {
                    setFieldValue("type", AccountType.Registry);
                    setFieldValue("nrmTermsAccepted", false);
                  }}
                  testID={`${selectedRegistry.code.toLowerCase()}-registry`}
                />
              </Flex>
              {values.type === AccountType.NonRegistry && (
                <NonRegistryTypeCheckBox
                  isDisabled={!!accountData?.account?.nrmTermsAcceptedDate}
                  t={t}
                  setShowLegalDocumentModal={setShowLegalDocumentModal}
                  isChecked={values.nrmTermsAccepted ?? Boolean(accountData?.account?.nrmTermsAcceptedDate)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue("nrmTermsAccepted", e?.target.checked)}
                  showLegalDocumentModal={showLegalDocumentModal}
                  loadingTranslatedFile={loadingTranslatedFile}
                  file={file}
                />
              )}
            </>
          )}
          {selectedRegistry && (
            <>
              <Box
                sx={{
                  borderWidth: "0.5px",
                  borderStyle: "solid",
                  borderColor: "textDarker",
                  my: 20,
                }}
              />
              {(!enableMultipleRegistry || (enableMultipleRegistry && values.type === AccountType.Registry)) && (
                <>
                  <Box sx={{ fontSize: 1, color: "textDarker", mb: 3 }}>
                    <Text sx={{ whiteSpace: "pre-line", fontFamily: "MintGroteskV08" }}>{guidanceText}</Text>
                  </Box>
                  <Field
                    name="accountNumber"
                    validate={(value: string) => validateAccountNumber(value, status, setStatus)}
                    registryCode={selectedRegistry?.code}
                    component={AccountNumberInput}
                  />
                </>
              )}
              <Flex
                sx={{
                  justifyContent: showModal ? "flex-end" : "flex-start",
                  alignItems: "center",
                  mt: 4,
                }}
              >
                {!showModal && (
                  <Button
                    variant="inverted"
                    sx={{ mr: 2 }}
                    onClick={() => {
                      setSelectedRegistry?.(false);
                      closeModal();
                    }}
                    type="button"
                  >
                    {t("Cancel")}
                  </Button>
                )}
                {showModal && status?.error && (
                  <Text sx={{ color: "error.500", fontSize: 1, mr: 3 }}>{t(status?.error)}</Text>
                )}
                {status?.success && (
                  <Text sx={{ color: "success.50", fontSize: 1, mr: 3 }}>
                    {t("The link request has been submitted. You'll be notified when it has been confirmed.")}
                  </Text>
                )}
                {!status?.success && (
                  <Flex sx={{ alignItems: "center" }}>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={isSubmitting || !isValid}
                      data-testid={"registry-form--link"}
                      onClick={() => handleSubmit()}
                    >
                      {t("Link to registry")}
                    </Button>
                    {isSubmitting && <IconLoading size="small" sx={{ ml: 2 }} />}
                  </Flex>
                )}
                {status?.success && (
                  <Button variant="primary" sx={{ px: 4 }} onClick={finishLinking}>
                    {isSubmitting ? <IconLoading /> : t("Finish")}
                  </Button>
                )}
                {!showModal && status?.error && (
                  <Text sx={{ color: "error.500", fontSize: 1, ml: 3 }}>{t(status?.error)}</Text>
                )}
              </Flex>
            </>
          )}
        </>
      );
    },
    [
      closeModal,
      showModal,
      finishLinking,
      guidanceText,
      registryOptions,
      selectedRegistry,
      setSelectedRegistry,
      singlePage,
      t,
      validateAccountNumber,
      linkedRegistriesData,
      isRetryState,
      file,
      loadingTranslatedFile,
      showLegalDocumentModal,
      loading,
      setShowLegalDocumentModal,
      accountData?.account?.nrmTermsAcceptedDate,
      enableMultipleRegistry,
    ],
  );
  return (
    <Formik
      initialValues={{
        registryId: "",
        type: null,
        accountNumber: "",
        nrmTermsAccepted: false,
      }}
      validationSchema={enableMultipleRegistry ? multipleLinkRegistrySchema : linkRegistrySchema}
      onSubmit={async ({ registryId, accountNumber, type }, { setStatus }) => {
        try {
          await linkRegistry({
            variables: {
              registryLink: {
                registryCode: selectedRegistry?.code as RegistryCode,
                termsAndConditionsAccepted: true,
                accountType: type ? (type as AccountType) : AccountType.Registry,
                registryId,
                registryOrgLinkId: accountNumber || undefined,
              },
            },
          });
          //remove selectedRegistry once linked
          setSelectedRegistry?.(false);
          setStatus({
            success: true,
            error: "",
          });
          singlePage && closeModal();
        } catch (e: any) {
          setStatus({
            success: false,
            error: e?.graphQLErrors[0]?.message || "Something went wrong",
          });
        }
      }}
    >
      {(props) => renderRegistryForm(props)}
    </Formik>
  );
};
