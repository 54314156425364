import { useFormikContext } from "formik";
import { isEqual } from "lodash";
import { useEffect } from "react";

import { useFormikInputError } from "@/app/hooks";
import { SignupStepperStepStageIndices } from "@/app/lib/account-service-helpers";
import { useOnboardingContext } from "@/app/pages/public/signup/onboarding/context";
import { CompanyContactFormInput } from "@/app/pages/public/signup/onboarding/onboarding.types";

import { UsePersonalInformationFormFn } from "./personal-information-form.types";

export const usePersonalInformationForm: UsePersonalInformationFormFn = () => {
  const { pageTouched, stepsDisabledFieldsMap, currentStep, currentStage } = useOnboardingContext();
  const { handleSubmit, setFieldValue, values, errors, setFieldTouched, touched } =
    useFormikContext<CompanyContactFormInput>();

  const page = isEqual(SignupStepperStepStageIndices.Applicant_Beneficial_Owners, [currentStep, currentStage])
    ? "Applicant_Beneficial_Owners"
    : "Applicant_Controller_Details";

  const { getInputStyles, getInputVariant, getRequiredFieldsMessage } = useFormikInputError(
    errors,
    touched,
    pageTouched?.includes(page),
  );

  useEffect(() => {
    if (!values?.isUSCitizen) {
      setFieldTouched("identityNumber", false, false);
    }
  }, [values, setFieldTouched]);

  /** For Beneficial Owner , form is not visible, but when it is, just enable all the fields*/
  const fieldsDisabled = page === "Applicant_Beneficial_Owners" ? false : !!stepsDisabledFieldsMap[page].length;
  return {
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    values,
    errors,
    fieldsDisabled,
    getInputStyles,
    getInputVariant,
    getRequiredFieldsMessage,
    touched,
    pageTouched,
  };
};
