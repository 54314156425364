import { Text, View } from "@react-pdf/renderer";
import currency from "currency.js";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import { OrderPosition, TradePosition } from "@/app/types/generated/graphql";

import { styles } from "../../pdf-styles";
import { DATE_TIME_FORMAT } from "../../react-pdf-helpers";
import { PairTableRow } from "../common/pair-table-row";
import { ProductAttributesTable } from "./product-attributes-table";
import { TransactionSummaryTransactionContract } from "./transaction-summary.types";

export const TransactionTable = ({
  transactionIndex,
  transaction,
  tradeType,
  orderPosition,
}: {
  transactionIndex: number;
  transaction: TransactionSummaryTransactionContract;
  orderPosition: TradePosition | OrderPosition;
  tradeType: "Spot" | "Forward";
}) => {
  const { t } = useTranslation();
  return (
    <>
      <View
        style={{ display: "flex", flexDirection: "column", marginTop: transactionIndex !== 0 ? 26 : 16 }}
        break={transactionIndex !== 0}
      >
        <View style={{ display: "flex", flexDirection: "row", gap: 3 }}>
          <Text style={styles.text}>{t(`${tradeType === "Forward" ? "Forward " : ""}Contract ID:`)}</Text>
          <Text style={styles.text}>{transaction.contractId || t("N/A")}</Text>
        </View>

        <View style={styles.tableContainer} wrap={false}>
          <PairTableRow leftValue={t("Marketplace")} rightValue={transaction.marketplace} />
          <PairTableRow leftValue={t("Participant")} rightValue={transaction.participantName} />
          {transaction.counterPartyName ? (
            <PairTableRow leftValue={t("Counterparty")} rightValue={transaction.counterPartyName} />
          ) : (
            <></>
          )}
          <PairTableRow leftValue={t("Order Type")} rightValue={transaction.position} />
          <PairTableRow
            leftValue={t("Trade Date")}
            rightValue={format(new Date(transaction.matchedDate), DATE_TIME_FORMAT)}
          />
          <PairTableRow
            leftValue={t("Settlement Date")}
            rightValue={format(new Date(transaction.completedDate), DATE_TIME_FORMAT)}
          />
          <PairTableRow
            leftValue={t("Product")}
            rightValue={t("As described in the Contract Product Attributes below")}
          />
          <PairTableRow
            leftValue={t("Product Price (Per Unit)")}
            rightValue={currency(transaction.price, { fromCents: true }).format()}
          />
          <PairTableRow leftValue={t("Product Quantity")} rightValue={transaction.volume.toLocaleString()} />
          <PairTableRow
            leftValue={t("Total Contract Value (inclusive of Transaction Fee and any applicable Taxes)")}
            rightValue={currency(transaction.totalContractValue, { fromCents: true }).format()}
          />
          <PairTableRow
            leftValue={t("Transaction Fee of {{value}}% (or as otherwise notified by the Market Operator)", {
              value: transaction.transactionFeePercentage,
            })}
            rightValue={currency(transaction.transactionFeeAmount, { fromCents: true }).format()}
          />
        </View>
      </View>

      {transaction.productAttributes.map((attributes, index) => (
        <ProductAttributesTable key={index} position={orderPosition} tradeType={tradeType} attributes={attributes} />
      ))}
    </>
  );
};
