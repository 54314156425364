import { Box, Button, Flex, IconLoading, Modal, Paragraph } from "@powerledger/ui-component-lib";
import { Field, Formik, FormikErrors, useFormikContext } from "formik";
import { sumBy } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "react-table";
import * as Yup from "yup";

import { getCommonRecTableData } from "@/app/pages/recs/common/rec-table-column";
import { RecHoldingsTableData } from "@/app/pages/recs/rec-holdings/rec-holdings.types";

import { AppCleaveTypes, CleaveInput } from "../cleave-input";
import { AttributesTooltip, GenericTooltip } from "../tooltips";
import { RecsAssignFormTable } from "./components/recs-assign-form-table/recs-assign-form-table";
import { getRecsAssignedColumns } from "./components/recs-assigned-list-table/recs-assigned-list-column";
import { RecsAssignedListTable } from "./components/recs-assigned-list-table/recs-assigned-list-table";
import {
  AssignAssetsFormValues,
  CustomVolumeError,
  ForwardTradeRecsAssignViewProps,
  TradeContractAssetsTableData,
} from "./forward-trade-recs-assign-modal.types";

export const ForwardTradeRecsAssignModalView = ({
  visible,
  closeModal,
  contractAssetsTableData,
  recsHoldingTableData,
  tradeContractNumber,
  recOptions,
  contractAssetsLoading,
  recsHoldingLoading,
  reloadTradeContractAssest,
  reloadRecsHolding,
  formikProps,
  onAssignRecs,
  pageInfo,
  fetchData,
  currentRequiredVolume,
  showAssignRECsTable,
  showViewRECsTable,
  totalAssignedRecs,
}: ForwardTradeRecsAssignViewProps) => {
  const { t } = useTranslation();

  const recsHoldingTableColumns: Column<RecHoldingsTableData>[] = useMemo(
    () => [
      ...getCommonRecTableData(t, recOptions),
      {
        Header: "Assign RECs",
        id: "assignRecs",
        Cell: ({ row }: { row: Row<RecHoldingsTableData> }) => (
          <RecsAssignInput index={row.index} availableBalance={row.original.availableBalance} />
        ),
      },
    ],
    [t, recOptions],
  );

  const contractAssetsTableColumns: Column<TradeContractAssetsTableData>[] = useMemo(
    () => getRecsAssignedColumns(t, recOptions),
    [t, recOptions],
  );

  return (
    <Modal
      visible={visible}
      onCancel={closeModal}
      title={t("REC Assignment")}
      subtitle={t("Assign RECs to your forward contract: {{tradeContractNumber}}", {
        tradeContractNumber,
      })}
      sx={{
        maxHeight: 700,
        overflow: "scroll",
      }}
    >
      {showAssignRECsTable && (
        <Formik
          initialValues={formikProps.initialValues as AssignAssetsFormValues}
          validationSchema={Yup.object().shape({
            assetsToAssign: Yup.array().of(
              Yup.object().shape({
                volume: Yup.number()
                  .required(t("Enter valid amount"))
                  .max(Yup.ref("availableVolume") || 0, t("You don't have enough RECs"))
                  .test("whole-number", t("Enter valid amount"), (value = 0) => Number.isInteger(value)),
              }),
            ),
          })}
          validate={(values) => {
            const totalAssignedVolume = sumBy(values.assetsToAssign, (item) => Number(item.volume));
            if (totalAssignedVolume > currentRequiredVolume) {
              return {
                customVolumeError: t("You can't assign more than required recs"),
              };
            }
            if (totalAssignedVolume === 0) {
              return {
                customVolumeError: t("Total assigned RECs cannot be zero"),
              };
            }
            return {};
          }}
          onSubmit={async (values, { resetForm }) => {
            await onAssignRecs(values);
            resetForm();
          }}
          validateOnMount
          enableReinitialize
          {...formikProps}
        >
          {({ isValid, isSubmitting, handleSubmit }) => {
            return (
              <>
                <RecsAssignFormTable
                  tableColumns={recsHoldingTableColumns}
                  tableData={recsHoldingTableData}
                  reloadTable={reloadRecsHolding}
                  loading={recsHoldingLoading}
                  askTradeVolume={currentRequiredVolume}
                  pageInfo={pageInfo}
                  fetchData={fetchData}
                />
                <Flex
                  sx={{
                    justifyContent: "flex-end",
                    gap: 2,
                    mt: 3,
                  }}
                >
                  <Button variant="error" onClick={closeModal} disabled={isSubmitting}>
                    {t("Cancel")}
                  </Button>
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={!isValid || isSubmitting}
                    onClick={() => handleSubmit()}
                  >
                    {t("Assign RECs")}
                  </Button>
                  {isSubmitting && <IconLoading size="small" />}
                </Flex>
              </>
            );
          }}
        </Formik>
      )}

      {showAssignRECsTable && showViewRECsTable && (
        <Box
          sx={{
            borderWidth: "0.5px",
            borderStyle: "solid",
            borderColor: "textDarker",
            my: 20,
          }}
        />
      )}

      {showViewRECsTable && (
        <RecsAssignedListTable
          tableColumns={contractAssetsTableColumns}
          tableData={contractAssetsTableData}
          reloadTable={reloadTradeContractAssest}
          loading={contractAssetsLoading}
          totalAssignedRecs={totalAssignedRecs}
        />
      )}
      <GenericTooltip />
      <AttributesTooltip />
    </Modal>
  );
};

const RecsAssignInput = ({ index = 0, availableBalance }: { index: number; availableBalance: number }) => {
  const { errors, touched } = useFormikContext<AssignAssetsFormValues>();
  const volumeTouched = touched?.assetsToAssign?.[index]?.volume;
  const volumeError =
    volumeTouched &&
    (errors?.assetsToAssign?.[index] as FormikErrors<AssignAssetsFormValues["assetsToAssign"]["0"]>)?.volume;

  //when total assigned volume exceeds askTradeVolume
  const customVolumeError = volumeTouched && (errors as FormikErrors<CustomVolumeError>)?.customVolumeError;

  return (
    <>
      <Field
        component={CleaveInput}
        type={AppCleaveTypes.Quantity}
        variant="input"
        name={`assetsToAssign.${index}.volume`}
        disabled={availableBalance <= 0}
      />
      <Paragraph
        sx={{
          fontSize: 0,
          color: "error.500",
          minWidth: 120,
        }}
      >
        {volumeError ?? customVolumeError}
      </Paragraph>
    </>
  );
};
