import { useTablePaginationManager } from "@powerledger/ui-component-lib";
import { subYears } from "date-fns";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ForwardTradeWithdrawCustomProps } from "@/app/components/forward-trade-withdraw-modal/forward-trade-withdraw-modal.types";
import { defaultFilter, mapStackedFilterStateToFilterState } from "@/app/components/page-header/filter";
import { FilterState } from "@/app/components/page-header/filter/filter.types";
import { TransactionSummary } from "@/app/components/pdf-components/documents/transaction-summary";
import { TransactionSummaryData } from "@/app/components/pdf-components/documents/transaction-summary/transaction-summary.types";
import { useHasPermissions } from "@/app/hooks/use-has-permission";
import { UserFeatures } from "@/app/hooks/use-okta-profile-features";
import { useRecOptions } from "@/app/hooks/use-rec-options";
import { getDatePart } from "@/app/lib/date-helpers";
import { formatAttributes, ReturnTypeFormatAttributes } from "@/app/lib/format-attributes";
import { ModalContext } from "@/app/modal-provider/modal-context";
import {
  SortOrderInput,
  TradeAttributes,
  TradeContract,
  TradeContractStatus,
  TradePosition,
  useTradeContractHistoryQuery,
  useTradeContractTransactionSummaryLazyQuery,
} from "@/app/types/generated/graphql";

import { SupportedDocumentType } from "../components/download-report-buttons";
import { getForwardTradeColumns } from "./forward-trade-columns";
import {
  AssignRecsProps,
  PartialAccountInfo,
  PartialCounterAccountInfo,
  TradeContractHistoryTableData,
  UseForwardTradeHistoryFn,
} from "./forward-trade-history.types";
import { downloadTransactionSummaryReport } from "./helpers";

export const useForwardTradeHistory: UseForwardTradeHistoryFn = () => {
  const { hasFeaturePermission } = useHasPermissions({ skipQuery: true });
  const [showAllAttributes, setShowAllAttributes] = useState(false);
  const { t } = useTranslation();
  const [activeFilters, setActiveFilters] = useState<FilterState>(defaultFilter);
  const { showForwardTradeRecsAssignModal, showForwardTradeRecsWithdrawModal, showForwardTradeDetailsModal } =
    useContext(ModalContext);

  const { pageInfo, offset, fetchData, setTotalItems, sort, resetPage } = useTablePaginationManager<
    TradeContract,
    SortOrderInput[]
  >({});

  const { recOptions, loading: recOptionsLoading } = useRecOptions();

  const { data, loading, refetch } = useTradeContractHistoryQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        paginationInput: {
          offset,
          limit: pageInfo.pageSize,
        },
        sortOrderInputs: sort,
        where: {
          recAttributes: {
            vintages: activeFilters.vintages,
            eligibilities: activeFilters.eligibilities,
            locations: activeFilters.locations,
            fuelSources: activeFilters.fuelSources,
            commencementDateGte: activeFilters.cod?.length
              ? getDatePart(subYears(new Date(), +activeFilters.cod?.[0]))
              : undefined,
            certifications: activeFilters.certifications,
          },
          statuses: activeFilters.statuses as TradeContractStatus[],
          tradeSettlementDate: activeFilters.selectedDate[0],
        },
      },
    },
    onCompleted(data) {
      setTotalItems(data.tradeContractHistory.offsetInfo.total ?? 0);
    },
  });

  const [transactionSummaryQuery] = useTradeContractTransactionSummaryLazyQuery({
    fetchPolicy: "no-cache",
  });

  //added new attribute types on "formatAttributes"
  const tableData = useMemo(() => {
    const tradeContractHistoryTableData: TradeContractHistoryTableData[] = [];

    if (!data?.tradeContractHistory.tradeContracts) return tradeContractHistoryTableData;
    for (const tradeContract of data.tradeContractHistory.tradeContracts) {
      const attributes = tradeContract.attributes as TradeAttributes;
      const account = tradeContract.account as PartialAccountInfo;
      const counterAccount = tradeContract.counterAccount as PartialCounterAccountInfo;
      const formattedAttributes = recOptions
        ? formatAttributes({
            attributes,
            /** For forward trades always display "Any" in case of attribute is not specified, regardless of Bid or Ask */
            position: TradePosition.Bid,
            options: recOptions,
          })
        : ({} as ReturnTypeFormatAttributes);
      tradeContractHistoryTableData.push({
        ...tradeContract,
        attributes,
        account,
        counterAccount,
        formattedAttributes: {
          ...tradeContract.attributes,
          vintages: formattedAttributes.vintages,
          eligibilities: formattedAttributes.eligibilities,
          certifications: formattedAttributes.certifications,
          fuelSources: formattedAttributes.fuelSources,
          locations: formattedAttributes.locations,
          projects: formattedAttributes.projects,
          certificationsAndEligibilities: formattedAttributes.certificationsAndEligibilities,
        },
      });
    }
    return tradeContractHistoryTableData;
  }, [recOptions, data?.tradeContractHistory.tradeContracts]);

  const reloadTradeContractHistory = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleAssignRecs = useCallback(
    ({
      tradeContractNumber,
      tradeContractId,
      askTradeVolume,
      registryCode,
      tradeAttributes,
      status,
    }: AssignRecsProps) => {
      showForwardTradeRecsAssignModal({
        tradeContractNumber,
        tradeContractId,
        askTradeVolume,
        registryCode,
        tradeAttributes,
        status,
      });
    },
    [showForwardTradeRecsAssignModal],
  );

  const handleWithdrawRecs = useCallback(
    ({ tradeID }: ForwardTradeWithdrawCustomProps) => {
      showForwardTradeRecsWithdrawModal({
        tradeID,
      });
    },
    [showForwardTradeRecsWithdrawModal],
  );

  // Workaround stop react-pdf rendering the pdf when the button renders and
  // only render the pdf when the button is clicked
  const downloadTransactionSummary = useCallback(
    async (
      type: SupportedDocumentType,
      {
        orderId,
        orderNumber,
      }: {
        orderId: string;
        orderNumber: string;
      },
    ) => {
      return downloadTransactionSummaryReport(
        type,
        orderId,
        orderNumber,
        recOptions,
        transactionSummaryQuery,
        (transactionSummaryData: TransactionSummaryData) => (
          <TransactionSummary
            title={"Marketplace Forward Contract Summary"}
            description={
              "This Document is a summary of your Forward Contract for the purchase or sale of Product in accordance with TraceX Rule 504(a)."
            }
            tradeType="Forward"
            transactionSummary={transactionSummaryData}
          />
        ),
      );
    },
    [recOptions, transactionSummaryQuery],
  );

  const tableColumns = useMemo(
    () =>
      getForwardTradeColumns(t, {
        handleAssignRecs,
        handleWithdrawRecs,
        showForwardTradeDetailsModal,
        downloadTransactionSummary,
      }),
    [t, handleAssignRecs, handleWithdrawRecs, showForwardTradeDetailsModal, downloadTransactionSummary],
  );

  const handleFilterChange = useCallback(
    (arg) => {
      setActiveFilters((activeFilters) => ({ ...activeFilters, ...mapStackedFilterStateToFilterState(arg) }));
      resetPage();
    },
    [resetPage],
  );

  return {
    forwardTradeEnabled: hasFeaturePermission([UserFeatures.FORWARD_TRADE_ENABLED]),
    tableData,
    loading: loading || recOptionsLoading,
    fetchData,
    pageInfo,
    reloadTradeContractHistory,
    tableColumns,
    showAllAttributes,
    setShowAllAttributes,
    handleFilterChange,
  };
};
