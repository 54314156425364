import { Defs, Image, LinearGradient, Rect, Stop, Svg, Text, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";

import powerledgerLogo from "@/assets/logos/logo-tracex.png?url";

import { colors, styles } from "../../pdf-styles";

export const TraceXPDFHeader = ({ title = "" }) => {
  const { t } = useTranslation();

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignContent: "center",
      }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
          padding: "32 32 32 32",
        }}
      >
        <View
          style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 10 }}
        >
          <Text style={{ ...styles.h1, fontSize: 28 }}>{t("TraceX")} </Text>
          <View style={{ display: "flex", flexDirection: "row", alignItems: "center", height: "auto" }}>
            <Text>{t("by ")}</Text>
            <Image style={{ height: 19, width: "auto" }} src={powerledgerLogo} />
          </View>
        </View>
        <Text style={{ ...styles.h1, marginTop: 20, marginBottom: 4 }}>{t(title)}</Text>
      </View>
      <View style={{ height: 8 }}>
        <Svg width="100%" height="100%" viewBox={"0 0 100 100"} preserveAspectRatio="none">
          <Defs>
            <LinearGradient id={"headerGradient"} x1={0} y1={0} x2={1} y2={0}>
              <Stop offset={"0%"} stopColor={colors.gradientYellow} />
              <Stop offset={"100%"} stopColor={colors.gradientPurple} />
            </LinearGradient>
          </Defs>
          <Rect x="0" y="0" width="100%" height="100%" fill="url('#headerGradient')" />
        </Svg>
      </View>
    </View>
  );
};
