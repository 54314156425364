import { Modal } from "@powerledger/ui-component-lib";

import { OrderLifeCycle } from "../common";
import { ForwardTradeForm } from "./forward-trade-form";
import { ForwardTradeOrderModalProps } from "./forward-trade-order-modal.types";
import { useForwardTradeModal } from "./use-forward-trade-order-modal";

export const ForwardTradeOrderModal: React.FC<ForwardTradeOrderModalProps> = ({
  defaultAsset,
  visible,
  closeModal,
}) => {
  const logic = useForwardTradeModal({ defaultAsset, closeModal });
  return (
    <Modal
      visible={visible}
      onCancel={closeModal}
      modalMaxWidth="1000px"
      containerOverflow="auto"
      sx={{
        ...(logic.currentStep === OrderLifeCycle.Form
          ? {
              overflow: "auto",
              maxHeight: "inherit",
            }
          : {}),
        color: logic.currentStep === OrderLifeCycle.Success ? "background" : "text",
      }}
    >
      <ForwardTradeForm {...logic} />
    </Modal>
  );
};
