import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useTranslatedFile } from "@/app/hooks";
import { useAllFeatureToggleValues } from "@/app/hooks/use-feature-toggle/use-all-feature-toggle-values";
import { validateRegistryId } from "@/app/lib/registry-helpers";
import {
  Registry,
  RegistryCode,
  useAccountLinkedRegistriesQuery,
  useAccountQuery,
  useLinkRegistryMutation,
  useRegistriesQuery,
} from "@/app/types/generated/graphql";

export const useRegistryForm = (selectedRegistry?: Registry | null) => {
  const { t } = useTranslation();

  const [showLegalDocumentModal, setShowLegalDocumentModal] = useState(false);
  const { loading: loadingTranslatedFile, file } = useTranslatedFile("translation", "productHoldingTerms");

  const {
    toggles: { enableMultipleRegistry, enableErcotIntegration },
  } = useAllFeatureToggleValues();

  const { data: registriesData, loading: registriesLoading } = useRegistriesQuery();
  const { data: accountData, loading: accountDataLoading } = useAccountQuery({
    skip: !enableMultipleRegistry,
  });
  const { data: linkedRegistriesData, loading: linkedRegistriesLoading } = useAccountLinkedRegistriesQuery({
    fetchPolicy: "cache-and-network",
    skip: !enableMultipleRegistry,
  });

  const [linkRegistry] = useLinkRegistryMutation({
    refetchQueries: ["AccountLinkedRegistries"],
  });

  const registryOptions = useMemo(
    () =>
      registriesData?.registries?.filter((reg) =>
        enableErcotIntegration ? reg.code !== RegistryCode.Icx : reg.code === RegistryCode.Mrets,
      ) || [],
    [registriesData?.registries, enableErcotIntegration],
  );

  const guidanceText = useMemo(() => {
    const guidanceMessages: Partial<Record<RegistryCode, string>> = {
      [RegistryCode.Mrets]: t(
        'Need to find your ID? You can access it by logging into your M-RETS account, accessing your profile on the top-right, and then clicking on "Organization Info". \nOnce you have sent a link request, you could accept the market invite from your M-RETS dashboard. \nThis is required for you trade on TraceX.',
      ),
      [RegistryCode.Ercot]: t("Please enter your business’s name as registered with ERCOT."),
    };
    return selectedRegistry?.code ? guidanceMessages[selectedRegistry.code as RegistryCode] : "";
  }, [selectedRegistry?.code, t]);

  const formatValidationText = useMemo(() => {
    const validationMessages: { [key in RegistryCode]?: string } = {
      [RegistryCode.Mrets]: t("Please enter valid MRETS registry ID"),
      [RegistryCode.Ercot]: t("Please enter valid ERCOT Account Name"),
    };

    return selectedRegistry?.code ? validationMessages[selectedRegistry.code as RegistryCode] : "";
  }, [selectedRegistry?.code, t]);

  const validateAccountNumber = useCallback(
    (value, status, setStatus) => {
      let error;
      if (!validateRegistryId(selectedRegistry?.code, value)) {
        error = formatValidationText;
      }
      setStatus({
        ...status,
        error,
      });

      return error;
    },
    [formatValidationText, selectedRegistry?.code],
  );

  return {
    showLegalDocumentModal,
    setShowLegalDocumentModal,
    file,
    registryOptions,
    linkedRegistriesData,
    loading: registriesLoading || linkedRegistriesLoading || accountDataLoading,
    loadingTranslatedFile,
    guidanceText,
    validateAccountNumber,
    accountData,
    linkRegistry,
    enableMultipleRegistry,
  };
};
