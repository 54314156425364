import { Button, LegacyCheckbox, Text } from "@powerledger/ui-component-lib";

import { LegalDocumentModal } from "@/app/components/legal-check-boxes/LegalDocumentModal";
import { TRACEX_PRODUCT_HOLDING_TERMS } from "@/app/lib/env-helpers";

import { NonRegistryTypeCheckBoxProps } from "./account-type-selection.types";

export const NonRegistryTypeCheckBox = ({
  isDisabled,
  t,
  setShowLegalDocumentModal,
  isChecked,
  onChange,
  loadingTranslatedFile,
  showLegalDocumentModal,
  file,
}: NonRegistryTypeCheckBoxProps) => {
  return (
    <>
      <LegacyCheckbox
        id={"nrmTermsAcceptedCheckbox"}
        disabled={isDisabled}
        name="nrmTermsAccepted"
        data-testid={"nrmTermsAcceptedCheckbox"}
        label={
          <Text>
            {t("By proceeding, you agree, on behalf of the Applicant entity, for the Applicant to be bound by the ")}
            <Button
              variant="text"
              sx={{
                fontFamily: "inter",
                textAlign: "left",
                fontWeight: "bold",
                textDecorationLine: "underline",
                color: "primary.600",
              }}
              onClick={() => setShowLegalDocumentModal(true)}
            >
              {t("Product Holding Terms")}
            </Button>
            {t(
              ", and acknowledge that the Products will be held pursuant to these terms on the Applicant's behalf for the purposes retirement only (they will not be able to be resold). Tick the box to indicate your acceptance.",
            )}
          </Text>
        }
        sx={{ mt: 3, fontFamily: "MintGroteskV08", fontSize: 0 }}
        checked={isChecked}
        onChange={onChange}
      />

      <LegalDocumentModal
        visible={showLegalDocumentModal}
        content={loadingTranslatedFile ? undefined : file}
        closeModal={() => setShowLegalDocumentModal(false)}
        download={TRACEX_PRODUCT_HOLDING_TERMS}
      />
    </>
  );
};
