import { Document, Page, Text, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";

import { OrderStatus, TradeContractStatus } from "@/app/types/generated/graphql";

import { styles } from "../../pdf-styles";
import { TraceXPDFFooter } from "../common/footer";
import { TraceXPDFHeader } from "../common/header";
import { OrderTable } from "./order-table";
import { TransactionSummaryData } from "./transaction-summary.types";
import { TransactionTable } from "./transaction-table";

export const TransactionSummary = ({
  title,
  description,
  transactionSummary,
  tradeType,
}: {
  title: string;
  description: string;
  tradeType: "Spot" | "Forward";
  transactionSummary: TransactionSummaryData;
}) => {
  const { t } = useTranslation();
  return (
    <Document
      title={`${tradeType} Trade Transaction Summary`}
      author={"Powerledger"}
      subject={transactionSummary.order.orderNumber}
      creator={"TraceX"}
      producer={"TraceX"}
    >
      <Page
        size="A4"
        orientation="portrait"
        style={{
          ...styles.text,
          flexDirection: "column",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <View fixed>
          <TraceXPDFHeader title={title} />
        </View>
        <View style={{ padding: "24 32 24 32", flexGrow: 1 }}>
          <View style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <Text style={styles.text}>{t(description)}</Text>
            <Text style={styles.text}>
              {t("If any details below are incorrect, please contact the market operator immediately")}
            </Text>
          </View>

          {[TradeContractStatus.Withdrawn, OrderStatus.PartialWithdrawn].includes(transactionSummary.order.status) &&
          transactionSummary.transactions.length === 0 ? (
            <View>
              <Text style={styles.h2}>{t("Order withdrawn before any transaction made.")}</Text>
            </View>
          ) : (
            transactionSummary.transactions.map((transaction, index) => (
              <TransactionTable
                key={transaction.contractId}
                transactionIndex={index}
                tradeType={tradeType}
                transaction={transaction}
                orderPosition={transactionSummary.order.position}
              />
            ))
          )}

          <View break style={{ display: "flex", flexDirection: "column", marginTop: 26 }}>
            <View style={{ display: "flex", flexDirection: "column", gap: 16 }}>
              <Text style={styles.h2}>{t("Here are details of the Order at the time of placing:")}</Text>
              <View style={{ display: "flex", flexDirection: "row", gap: 3 }}>
                <Text style={styles.text}>{t("Order ID:")}</Text>
                <Text style={styles.text}>{transactionSummary.order.orderNumber}</Text>
              </View>
            </View>

            <OrderTable tradeType={tradeType} order={transactionSummary.order} />
          </View>
        </View>
        <View fixed>
          <TraceXPDFFooter />
        </View>
      </Page>
    </Document>
  );
};
