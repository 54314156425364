import { createContext, useContext } from "react";

import { ModalHandler } from "./modal-provider.types";

export const ModalContext = createContext({} as ModalHandler);
export const useModalContext = () => useContext(ModalContext);
export const ModalContextProvider: React.FC<{ values: ModalHandler; children: React.ReactNode }> = ({
  values,
  children,
}) => <ModalContext.Provider value={values}>{children}</ModalContext.Provider>;
