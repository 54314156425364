import { Image, Text, View } from "@react-pdf/renderer";

//@ts-ignore
import powerledgerLogo from "@/assets/logos/powerledger-logotype-colour-digital.png";

import { styles } from "../../pdf-styles";

export const TraceXPDFFooter = () => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignContent: "center",
        margin: "40 32 40 32",
      }}
    >
      <Image style={{ height: 32 }} src={powerledgerLogo} />
      <View style={{ display: "flex", flexDirection: "column", margin: "6 12" }}>
        <Text
          style={{
            ...styles.finePrint,
            marginBottom: 2,
          }}
        >{`Level 2, The Palace, 108 St George’s Terrace, Perth, WA, 6000, Australia`}</Text>
        <Text
          style={{
            ...styles.finePrint,
          }}
        >{`+61 8 9322 6659 | sales@powerledger.io | ABN 39 612 531 218`}</Text>
      </View>
    </View>
  );
};
