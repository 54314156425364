import { Box, Button, Flex, Text, Tooltip } from "@powerledger/ui-component-lib";
import currency from "currency.js";
import { format } from "date-fns";
import { TFunction } from "i18next";
import { Column, Row } from "react-table";

import { EligibilityValueFormatter } from "@/app/components/eligibility-value-formatter";
import { LocationValueWrapper } from "@/app/components/location-value-wrapper/location-value-wrapper";
import { TableValueWrapper } from "@/app/components/table-value-wrapper";
import { CertificationAndEliglibilityType } from "@/app/lib/format-attributes";
import { RECOrderAttributeOptions } from "@/app/lib/format-rec-options";
import { getFuelSourceIcon } from "@/app/lib/get-fuel-source-icon";
import { CounterOrderDirection, OrderPosition } from "@/app/types/generated/graphql";

import { CounteredOrdersData, HeadersTitle } from "./countered-orders.types";

export const getCounteredOrdersColumn = (
  t: TFunction,
  onSeeCounterOffers: (recs: CounteredOrdersData) => void,
  counterOrderDirection: CounterOrderDirection,
  recOptions?: RECOrderAttributeOptions,
) => {
  const getFuelSourceLabel = (key: string) => {
    const filteredFuelSource = recOptions?.fuelSourceOptions.filter((x) => x.value === key);
    return filteredFuelSource?.[0]?.label ?? "";
  };
  const tableColumns: Column<CounteredOrdersData>[] = [
    {
      Header: () => t(HeadersTitle[counterOrderDirection]),
      accessor: "orderNumber",
      disableSortBy: true,
    },
    {
      Header: t("Vintage"),
      disableSortBy: true,
      accessor: (row) => row.vintages,
      Cell: ({ value }: { value: string }) => (
        <TableValueWrapper value={value} csv title="Vintages" containerSx={{ minWidth: 80 }} />
      ),
    },
    {
      Header: t("Eligibility"),
      accessor: "certificationsAndEligibilities",
      disableSortBy: true,
      Cell: ({ value, row }: { row: Row<CounteredOrdersData>; value: CertificationAndEliglibilityType[] }) => (
        <EligibilityValueFormatter certificationsAndEligibilities={value} position={row.original.position} />
      ),
    },
    {
      Header: t("Project"),
      accessor: "projects",
      Cell: ({ value }: { value: string | string[] }) => {
        const projectValue = value || value?.length ? value : "-";
        return <TableValueWrapper value={projectValue} csv title={"Projects"} containerSx={{ minWidth: 100 }} />;
      },
      disableSortBy: true,
    },
    {
      Header: t("Fuel Type"),
      accessor: (row) => row.attributes?.fuelSources,
      Cell: ({ value }: { value: string[] }) => (
        <Flex sx={{ color: "textDark" }}>
          {!value || value.length < 1 ? (
            "-"
          ) : (
            <TableValueWrapper
              getIcon={getFuelSourceIcon}
              title="Fuel Sources"
              value={value}
              getToolTipText={getFuelSourceLabel}
            />
          )}
        </Flex>
      ),
      disableSortBy: true,
    },
    {
      Header: (
        <Box
          sx={{
            textAlign: "right",
            width: "100px",
          }}
        >
          {t("Price USD")}
        </Box>
      ),
      id: "unitPrice",
      width: "100px",
      disableSortBy: true,
      accessor: "price.amount" as keyof Pick<CounteredOrdersData, "price">,
      Cell: ({ value }: { value: string }) => (
        <Box
          sx={{
            textAlign: "right",
            width: "120px",
            color: "textDark",
            px: 2,
          }}
        >
          {currency(value || 0, { separator: ",", fromCents: true }).format()}
        </Box>
      ),
    },
    {
      Header: () => (
        <>
          <Text>{t("Quantity")}</Text>
          <Tooltip
            id="volume"
            content={<Text>{t("Remaining/Initial Quantity")}</Text>}
            sx={{
              svg: {
                width: 18,
                height: 18,
                path: {
                  fill: "primary.500",
                },
              },
            }}
          />
        </>
      ),
      id: "currentVolume",
      disableSortBy: true,
      Cell: ({ row }: { row: Row<CounteredOrdersData> }) => {
        const { currentVolume, initialVolume } = row.original;
        const value = `${currentVolume} / ${initialVolume}`;
        return <TableValueWrapper value={value} />;
      },
    },
    {
      Header: () => t("Order Type"),
      accessor: "position",
      disableSortBy: true,
      Cell: ({ value }) => (
        <Box
          sx={{
            variant: value === OrderPosition.Ask ? "chips.error" : "chips.info",
            width: "40px",
          }}
        >
          {value}
        </Box>
      ),
    },
    {
      Header: t("Commencement Date (COD)"),
      id: "commencementDate",
      Cell: ({ row }: { row: Row<CounteredOrdersData> }) => {
        const commencementDate = row.original.attributes?.commencementDate;
        const displayValue = commencementDate ? format(new Date(commencementDate), "P") : "N/A";
        return <TableValueWrapper value={displayValue} />;
      },
      disableSortBy: true,
    },
    {
      Header: t("Location"),
      accessor: "locations",
      Cell: ({ value }: { value?: string[] }) => (
        <LocationValueWrapper value={value ?? []} csv containerSx={{ minWidth: 110 }} />
      ),
      disableSortBy: true,
    },
    {
      Header: t("Action"),
      id: "action",
      Cell: ({ row }: { row: Row<CounteredOrdersData> }) => (
        <Button
          variant="pill.compactSecondary"
          sx={{ py: 1, mr: 2, minWidth: 155 }}
          onClick={() => {
            onSeeCounterOffers(row.original);
          }}
        >
          {t("See Counter Offers")}
        </Button>
      ),
    },
  ];

  return tableColumns;
};
