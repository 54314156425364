import { FC } from "react";

import { RegistryFormProps } from "@/app/pages/settings/registry-tab/linked-registries/linked-registries.types";

import { RegistryFormView } from "./registry-form-view";
import { useRegistryForm } from "./use-registry-form";

export const RegistryForm: FC<RegistryFormProps> = (props) => {
  const logic = useRegistryForm(props.selectedRegistry);

  return <RegistryFormView {...logic} {...props} />;
};
