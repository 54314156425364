import { Flex, IconCircleTick, IconWarning, useTheme } from "@powerledger/ui-component-lib";
import Cleave from "cleave.js/react";

import { RegistryCode } from "@/app/types/generated/graphql";

import { AccountNumberInputProps } from "./account-number-input.types";

const cleaveOption = (registryCode = "") => {
  switch (registryCode) {
    case "MRETS":
      return {
        prefix: "MM",
        // noImmediatePrefix:false will strangely not take in field.value in cleave
        noImmediatePrefix: true,
        numericOnly: true,
      };
    case "ERCOT":
      return {
        noImmediatePrefix: true,
        blocks: [50],
        delimiter: "",
      };

    default:
      return { blocks: [8, 4, 4, 4, 12], delimiter: "-" };
  }
};

export const AccountNumberInput: React.FC<AccountNumberInputProps> = ({ field, form, registryCode }) => {
  const { theme } = useTheme();
  const options = cleaveOption(registryCode);
  return (
    <Flex
      sx={{
        alignItems: "center",
        position: "relative",
        width: "100%",
        input: {
          ...theme.forms?.input,
          fontSize: 4,
          fontFamily: "IBMPlexMono",
          letterSpacing: 4,
          px: 3,
          py: 4,
          color: form.status?.error ? "yellow" : form.status?.success ? "positive" : "inherit",
          position: "relative",
          "::placeholder": {
            color: "textDark",
            fontSize: 2,
            letterSpacing: 0,
            opacity: 0.6,
          },
        },
      }}
    >
      <Cleave
        key={JSON.stringify(options)}
        options={options}
        name={field.name}
        onChange={(e) => form.setFieldValue(field.name, e.target.value.trim())}
        onBlur={field.onBlur}
        // Default value MM as due to noImmediatePrefix:true we wont have a prefix when the input is not autofocused
        // Adding autoFocus will give eslint error
        value={registryCode === RegistryCode.Mrets ? field.value || "MM" : field.value}
        placeholder={registryCode === RegistryCode.Ercot ? "ERCOT Business Name" : ""}
        data-test-id={`${(registryCode ?? "").toLowerCase()}-${field.name.toLowerCase()}`}
      />
      <Flex
        sx={{
          position: "absolute",
          right: 2,
          color: "textDarker",
          fontWeight: "light",
        }}
      >
        {form.status?.error && <IconWarning color="yellow" size={4} noSvgPositioning />}
        {form.status?.success && <IconCircleTick color="positive" size={5} noSvgPositioning sx={{ mt: 2 }} />}
      </Flex>
    </Flex>
  );
};
