import { useOktaAuth } from "@okta/okta-react";

import { useAppContext } from "@/app/context";
import { isOnboardingOrRetryUser } from "@/app/lib/account-service-helpers";
import { grantFeatureAccess } from "@/app/lib/feature-based-access-control";
import { grantAccess } from "@/app/lib/role-based-access-control";
import { AccountStatus, RegistryCode } from "@/app/types/generated/graphql";

import { FeatureToggleKey } from "../use-feature-toggle";
import { useAllFeatureToggleValues } from "../use-feature-toggle/use-all-feature-toggle-values";
import { useOktaProfileFeatures, UserFeatures } from "../use-okta-profile-features";
import { useOktaRoles } from "../use-okta-roles";
import { PermissionGuard, UseHasPermissions } from "./use-has-permission.types";

export const useHasPermissions: UseHasPermissions = () => {
  const { authState } = useOktaAuth();
  const { userAccountInfo, userSelections } = useAppContext();

  const { userRoles } = useOktaRoles();
  const { oktaProfileFeatures } = useOktaProfileFeatures();
  const { toggles, loading: featureToggleLoading } = useAllFeatureToggleValues();

  const hasPermissions = (guard: PermissionGuard) => {
    if (!userAccountInfo?.loaded) return false;
    const isRoleAllowed = hasRolePermissions(guard.allowedRoles);
    const isStatusAllowed = hasStatusPermissions(guard.allowedStatuses);
    const isTypeAllowed = hasTypePermissions(guard.allowedAccountTypes);
    const isFeatureTogglesEnabled = isRequiredFeatureTogglesEnabled(guard.requiredFeatureToggles);
    const isRequiredRegistries = hasRequiredRegistries(guard.allowedRegistries);
    return isRoleAllowed && isStatusAllowed && isTypeAllowed && isFeatureTogglesEnabled && isRequiredRegistries;
  };

  const hasTypePermissions = (allowedAccountTypes: PermissionGuard["allowedAccountTypes"]) => {
    if (!userAccountInfo?.loaded || !userAccountInfo?.accountType) return false;

    return allowedAccountTypes ? allowedAccountTypes.includes(userAccountInfo?.accountType) : true;
  };

  const hasStatusPermissions = (allowedStatuses: PermissionGuard["allowedStatuses"]) => {
    if (!userAccountInfo?.loaded || !userAccountInfo?.status) return false;
    return allowedStatuses ? allowedStatuses.includes(userAccountInfo?.status) : true;
  };

  const hasRolePermissions = (allowedRoles: PermissionGuard["allowedRoles"]) => {
    return grantAccess(userRoles, allowedRoles);
  };

  const hasFeaturePermission = (requiredFeaturesClaims?: UserFeatures[]) => {
    return grantFeatureAccess(oktaProfileFeatures, requiredFeaturesClaims);
  };

  const hasRequiredRegistries = (allowedRegistries?: RegistryCode[]) => {
    if (!allowedRegistries?.length) return true;
    const registryCode = userSelections?.registry?.registryCode;
    return registryCode ? allowedRegistries.includes(registryCode) : false;
  };

  const isRequiredFeatureTogglesEnabled = (requiredFeatureToggles?: FeatureToggleKey[]) =>
    requiredFeatureToggles ? requiredFeatureToggles.every((toggle) => !!toggles[toggle]) : true;

  const isActorOnboarding = isOnboardingOrRetryUser(userAccountInfo?.status);

  const isActorVerified = userAccountInfo?.status === AccountStatus.Active;

  const loading = (authState?.isAuthenticated && !userAccountInfo?.loaded) || featureToggleLoading;

  return {
    hasPermissions,
    hasRolePermissions,
    hasFeaturePermission,
    hasStatusPermissions,
    hasTypePermissions,
    isRequiredFeatureTogglesEnabled,
    hasRequiredRegistries,
    loading,
    isActorOnboarding,
    isActorVerified,
  };
};
